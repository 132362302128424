import React from "react";
import { Rect, Transformer } from "react-konva";

const Rectangle = ({ shapeProps, isSelected, onSelect, onChange }) => {
	
	const shapeRef = React.useRef();
	const trRef = React.useRef();
	const canTransform = isSelected && !shapeProps.locked;
	
	

	React.useEffect(() => {
		if (canTransform) {
			//attaching transformer manually
			trRef.current.setNodes([shapeRef.current]);
			trRef.current.getLayer().batchDraw();
		}
	}, [canTransform]);

	return (
		<React.Fragment>
			<Rect
				onClick={!shapeProps.locked && onSelect}
				listening={!shapeProps.locked} //can it listen to events?
				ref={shapeRef}
				{...shapeProps}
				draggable={canTransform}
				onDragEnd={e => {
					onChange({
						...shapeProps,
						x: e.target.x(),
						y: e.target.y(),
					});
					onSelect();
				}}
				onTransformEnd={e => {
					//here the transform will change the scale
					const node = shapeRef.current;
					const scaleX = node.scaleX();
					const scaleY = node.scaleY();
					node.scaleX(1);
					node.scaleY(1);
					onChange({
						...shapeProps,
						rotation: node.rotation(),
						x: node.x(),
						y: node.y(),
						width: node.width() * scaleX,
						height: node.height() * scaleY,
					});
				}}
			/>
			{canTransform && <Transformer ref={trRef} />}
		</React.Fragment>
	);
};
export default Rectangle;




















