import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        main: '#3c9d90',
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        light: '#5d4d60',
        main: '#5d4d60',
        // dark: will be calculated from palette.secondary.main,
        contrastText: '#ffffff',
      },
      neutral: {
        main: '#222222',
        contrastText: '#fff',
      },
      
    },
    typography: {
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        'Poppins',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
      ].join(','),
    },
  });

  export default theme;
