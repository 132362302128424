import React from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import { Button } from '@mui/material';
import {LockOpenRounded, LockRounded, ContentCopy } from '@mui/icons-material';

export default function SortableLayer(props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: props.id});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const getShapeReadebleType = (type) => {
    let re = "";
    switch (type) {
      case "circ":
        re = "Circle";
        break;
      case "line":
        re = "Line";
        break;
      case "image":
        return "Image";
        break;
      case "rect":
        return "Rectangle";
        break;
      case "textelement":
        return "Text";
        break;
      case "qr":
        return "QR-code";
        break;
      default:
        break;
    }
    return re;
  }

  const truncateString = (str, num) => {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  }

  const lockIcon = () => {
    //console.log('locked',props.locked);
    //return <LockOpenRounded/>;
    return props.locked ? <LockRounded /> : <LockOpenRounded /> ;
  }

  return (
    <>

    <li ref={setNodeRef} style={style} {...attributes} {...listeners} className={props.classes} >
      <div onClick={() => props.onSelectLayer(props.id, props.anoterIndex)}>
        <span className="layerTag">{getShapeReadebleType(props.type)}</span> {props.text && truncateString(props.text, 15)}
      </div>

      <Button className="float-right" title={props.locked ? "Unlock layer" : "Lock layer" } onClick={(e) => props.onLockLayer(e, props.anoterIndex)}>{lockIcon()}</Button>
      {!props.isDynamic && (<>
        <Button className="float-right" title={"Copy layer"} onClick={(e) => props.onCopyLayer(e, props.anoterIndex)} ><ContentCopy /></Button>
      </>)}
    </li>


    </>

  );
}


