import React, { useState, useEffect } from "react";
import { Button } from '@mui/material';

import { fetchTemplateList } from '../utils/remoteOperations'
import ReactSelect from 'react-select';




const TemplateSelector = ({ url, changeTemplate, activeTemplate, handleClose }) => {

  const [availableTemplates, updateTemplates] = useState([]);


  const GetTemplateList = () => {
    fetchTemplateList(url)
      .then(Templates => {
        // console.log(Templates);
        ProcessTemplateList(Templates);
      });
  }


  useEffect(() => {
    GetTemplateList();
  }, []);

  const [selectedTemplate, setSelectedTemplate] = useState()

  const [defaultIsMenuOpen, setDefaultIsMenuOpen] = useState()

  const [isLoading, setLoading] = useState(true)


  const ProcessTemplateList = (availTemplates) => {

    // flatten return list
    let TemplateList = [...availTemplates.global ?? [], ...availTemplates.custom ?? []]

    // sort by priority and name
    // default priority is 50
    TemplateList.sort((a, b) => {
      const ap = a.priority ?? 50
      const bp = b.priority ?? 50
      if (ap === bp) {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      } else {
        return ap < bp ? 1 : -1
      }
    })
    // console.log(TemplateList)
    updateTemplates(TemplateList);
    // setDefaultIsMenuOpen(true)
    setLoading(false);

    console.log('availableTemplates',TemplateList);
  }



  const loadTemplate = () => {
    changeTemplate(selectedTemplate, handleClose)
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      background: state.isSelected ? '#A2A2A2' : '#EAEAEA',
      padding: 20,
      width: "100%",

    }),
    // TODO: Fix menu "fixed", problme is zero height on first open.
    menu: (provided, state) => ({
      ...provided,

      // maxHeight: 600,
    }),
    menuList: (provided, state) => ({
      ...provided,
      // minHeight: "300px"
      // position: "fixed",
      // overflow: false,
      // maxHeight: 350,
    }),
    control: (provided, state) => ({
      ...provided,
      background: '#EAEAEA',
      width: 315,
      //   // none of react-select's styles are passed to <Control />
      //   width: 200,
    }),
    dropdownIndicator: (provided) => ({

      ...provided
      // ,
      //           "svg": {
      //             fill: "#3131FF"
      //           }
    }
    ),
    // singleValue: (provided, state) => {
    //   const opacity = state.isDisabled ? 0.5 : 1;
    //   const transition = 'opacity 300ms';

    //   return { ...provided, opacity, transition };
    // }
  }

  const options = availableTemplates

  const SelectMenu = () => {

    let value = selectedTemplate
    if (options.length === 0) {
      return null
    }

    let menuPos = "fixed"
    setDefaultIsMenuOpen(false)

    if (value === undefined && activeTemplate !== undefined && options.length > 0) {
      value = options.find((e) => e.name.toLowerCase() === activeTemplate.toLowerCase())
      // Problem with "fixed" menu position
      // initially the menu is hidden or of size 0
      // setDefaultIsMenuOpen(true)
    }



    // TODO: Move styles to more appropriate place from inline styles.
    return <ReactSelect
      styles={customStyles}
      maxMenuHeight="600"
      minMenuHeight="300"
      defaultValue={value}
      defaultMenuIsOpen={defaultIsMenuOpen}
      menuPlacement="auto"
      menuPosition={menuPos}
      isClearable={false}
      onChange={setSelectedTemplate}
      placeholder="Select template from the list"
      isLoading={isLoading}
      options={options}
      isSearchable={false}
      getOptionValue={(element) => `${element.name.toLowerCase()}`}
      formatOptionLabel={element => (
        <div className="template-option" style={{
          "textAlign": "center",

        }
        } >
          <h2>{element.name}</h2>
          <img src={element.thumbnail} alt={`preview-image: ${element.name} `} />
        </div >
      )}
    />

  }

  /* TODO: Select does not support images:
  * ReactSelect? https://stackoverflow.com/a/69830024/1142796
  */
  return (



    <div className="TemplateSelector-container">

     {/*  <SelectMenu /> */}
      <div className="row">
        <div className="col-6">
          <div className="tpl-list">
          {availableTemplates && availableTemplates.map((tpl) => {
          return <div className={selectedTemplate && selectedTemplate.name == tpl.name ? "tplName active" : "tplName"} onClick={()=>setSelectedTemplate(tpl)}>{tpl.name}</div>;
          })}
          </div>
        </div>
        {selectedTemplate && (
        <div className="col-6 tpl-thumb">
          <img src={selectedTemplate.thumbnail} />
        </div>
        )}
      </div>

      <div className="row justify-content-center">
        <div className="col-6">
          <Button variant="contained" color="primary" className="mt-3 w-100" size="large" disabled={!selectedTemplate} onClick={loadTemplate} >Load</Button>
        </div>
      </div>


    </div>

  );
};


export default TemplateSelector;


