import React, {useState} from "react";
import { Image, Transformer } from "react-konva";
import useImage from "use-image";

const Img = ({ id,type,x,y,opacity,width, height, rotation, isSelected, onSelect, onChange, imageUrl, locked }) => {


	const [image, status] = useImage(imageUrl);

	const shapeRef = React.useRef();
	const trRef = React.useRef();
	const canTransform = isSelected && !locked;


	/* console.log(image, status, imageUrl, loadedImage); */

	React.useEffect(() => {
		if (canTransform) {
			//attaching transformer manually
			trRef.current.setNodes([shapeRef.current]);
			trRef.current.getLayer().batchDraw();
		}
	}, [canTransform]);

	return(

		<React.Fragment>
			<Image
				onClick={onSelect}
				image={image}
				ref={shapeRef}
				id={id}
				x={x}
				y={y}
				opacity={opacity}
				width={width}
				height={height}
				rotation={rotation}
				perfectDrawEnabled={false}
				type='image'
				draggable={canTransform}
				onDragEnd={e => {
					onChange({
						id:id,
						type:type,
						content: imageUrl,
						rotation: rotation,
						x: e.target.x(),
						y: e.target.y(),
						width: width,
						height:height,
					});
					onSelect();
				}}
				onTransformEnd={e => {
					const node = shapeRef.current;
					const scaleX = node.scaleX();
					const scaleY = node.scaleY();
					onChange({
						id:id,
						rotation: node.rotation(),
						type:type,
						content: imageUrl,
						x: node.x(),
						y: node.y(),
						width: node.width() * scaleX,
						height: node.height() * scaleY,
					});
				}}
			/>
			{canTransform && <Transformer ref={trRef} />}

		</React.Fragment>


	);
};



export default Img;
