import React from "react";
import { Line, Rect} from "react-konva";

const TransformableLine = ({ shapeProps, isSelected, onSelect, onChange }) => {
	//example for line
	//https://jsbin.com/wahetunepa/edit?html,js,output

	const shapeRef = React.useRef();
	const anchor1Ref = React.useRef();
	const anchor2Ref = React.useRef();
	/* const trRef = React.useRef(); */
	const canTransform = isSelected && !shapeProps.locked;


	React.useEffect(() => {
		if (canTransform) {
			/* trRef.current.setNodes([shapeRef.current]);
			trRef.current.getLayer().batchDraw(); */
		}
	}, [canTransform]);

	const getLinePoints = () => {
		console.log(anchor1Ref.current.attrs.x)
		let points = [
			anchor1Ref.current.attrs.x,
			anchor1Ref.current.attrs.y,
			anchor2Ref.current.attrs.x,
			anchor2Ref.current.attrs.y,
		];

		return points;
	}

	const updateLine = () => {
		shapeRef.current.x(0);
		shapeRef.current.y(0);
		shapeRef.current.attrs.points = getLinePoints();
	}

	const savePosition = () => {

		let points = getLinePoints();


		const angleDeg = Math.atan2(points[3]-points[1], points[2]-points[0]) * 180 / Math.PI;
		// console.log(angleDeg);

		onChange({
			...shapeProps,
			points,
			x: points[0],
			y: points[1],
			width: points[2]-points[0],
			height: points[3]-points[1],
			rotation: angleDeg

		});
	}

	const updateAnchors = (e) => {
		/* let p=({
			'x1': shapeRef.current.attrs.points[0],
			'y1': shapeRef.current.attrs.points[1],
			'x2': shapeRef.current.attrs.points[2],
			'y2': shapeRef.current.attrs.points[3]
		});
		var angleDeg = Math.atan2(p.y2 - p.y1, p.x2 - p.x1) * 180 / Math.PI;
		console.log(angleDeg); */
		anchor1Ref.current.x(shapeRef.current.x() + shapeRef.current.attrs.points[0]);
		anchor1Ref.current.y(shapeRef.current.y() + shapeRef.current.attrs.points[1])
		anchor2Ref.current.x(shapeRef.current.x() + shapeRef.current.attrs.points[2]);
		anchor2Ref.current.y(shapeRef.current.y() + shapeRef.current.attrs.points[3]);

	}
	let {x,y,width,height,rotation, ...copyProps} = shapeProps
	copyProps.x=0
	copyProps.y=0

	return (
		<React.Fragment>

			<Line
				onClick={!shapeProps.locked && onSelect}
				listening={!shapeProps.locked} //can it listen to events?
				ref={shapeRef}
				{...copyProps}
				draggable={canTransform}
				hitStrokeWidth={shapeProps.strokeWidth < 5 ? 5 : shapeProps.strokeWidth}
				onDragMove={e => {
					updateAnchors(e);
				}}
				onDragEnd={e => {
					updateAnchors(e);
					savePosition();
					onSelect()
				}}

			/>
			{canTransform &&
				<>
					<Rect ref={anchor1Ref} draggable={true} type="transformLeft" x={shapeProps.points[0]} y={shapeProps.points[1]} offsetX={5} offsetY={5} width={10} height={10}  fill="#FFFFFF" strokeWidth={1} stroke='rgb(0, 161, 255)' onDragMove={updateLine} onDragEnd={savePosition}></Rect>
					<Rect ref={anchor2Ref} draggable={true} type="transformRight" x={shapeProps.points[2]} y={shapeProps.points[3]} offsetX={5} offsetY={5}  width={10} height={10} fill="#FFFFFF" strokeWidth={1} stroke='rgb(0, 161, 255)' onDragMove={updateLine} onDragEnd={savePosition}></Rect>
				</>
			}

		</React.Fragment>
	);
};


export default TransformableLine;
