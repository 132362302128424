// we need deepcopy/clone
// import _ from "lodash";

const transposeMap = (obj) => {
    let ret = {}

    const toplevelKeys = Object.keys(obj ?? {})
    for (let i = 0; i < toplevelKeys.length; i++) {
        let toplevel = toplevelKeys[i]
        let nextlevelKeys = Object.keys(obj[toplevel])
        for (let j = 0; j < nextlevelKeys.length; j++) {
            let key = nextlevelKeys[j]
            if (!ret[key]) {
                ret[key] = {}
            }
            ret[key][toplevel] = obj[toplevel][key]
        }
    }
    return ret
}
// a = { en: { "gcd.text1": "text", "gcd.text2": "text2" }, fi: { "gcd.text1": "textfi", "gcd.text2": "text2fi" } }

export const i18nObj2UI = (i18nui) => {
    let i18n = transposeMap(i18nui)
    return i18n
}

export const i18nUI2Obj = (i18n) => {
    let i18nui = transposeMap(i18n)
    return i18nui
}



export default {
    transposeMap,
    i18nObj2UI,
    i18nUI2Obj,
    
}


