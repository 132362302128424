import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import { Stage, Layer, Line, Rect } from 'react-konva';
import { Text as KonvaText } from 'react-konva';

import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { SketchPicker } from 'react-color';

import { Button, ButtonGroup } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import Slider from '@mui/material/Slider';
import theme from './utils/materialUiTheme';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { ArrowDownward, ArrowUpward, FormatAlignCenter, FormatAlignLeft, FormatAlignRight, LockOpenRounded, LockRounded, VerticalAlignBottom, VerticalAlignCenter, VerticalAlignTop, CropDin, RadioButtonUnchecked, TextFields, ExpandMore, CheckCircleOutline, HorizontalRule } from '@mui/icons-material';

import isoLangs from './utils/isoLangs';

// import different elements
import TopBar from './components/TopBar';
import SortableLayer from './components/SortableLayer';
import Circle from './components/Circle';
import TransformableLine from './components/TransformableLine';
import Img from './components/Image';
import Qr from './components/Qr';

import Rectangle from './components/Rectangle';
import TextElement from './components/TextElement';
import ImageBank from './components/ImageBankLocalStorage';

import FontSelector from './components/FontSelector';
import SettingsModal from './components/SettingsModal';
import BrowseTemplatesModal from './components/BrowseTemplatesModal';
import objectHash from "object-hash";
import WebFont from 'webfontloader';
import { v1 as uuidv1 } from 'uuid';

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  restrictToVerticalAxis,
  restrictToParentElement,
} from '@dnd-kit/modifiers';

import { useLocation } from "react-router-dom";

// helps file downloading from react
import { saveAs } from 'file-saver';

// we need deepcopy/clone
import _, { isNull, template } from "lodash";



// load template zip file
// TODO: check this module more carefully
// https://stackoverflow.com/a/65600262/1142796
import { useFilePicker } from "use-file-picker";


import { LOCALSTORAGE, MANDATORYFIELDS, ZIPFILENAMES, UI, DYNAMICFIELDDEFAULTTEXT } from './constants';

import gc from 'templatesvgtopdf'


// import { bundleTemplateZip } from './utils/templatefileHandling'

import { fetchBackendSettings, fetchTemplateBundle, sendToRender, sendToStorage } from './utils/remoteOperations'

import { i18nObj2UI, i18nUI2Obj } from './utils/i18nHandling';

// shape definitions
import shapesHandling from './utils/shapesHandling';

// Maximum image size from UI constants
const imageSizeMiB = UI.imageSizeMiB || 0.8
const imageMaxHeight = UI.imageMaxHeight || 2500
const imageMaxWidth = UI.imageMaxWidth || 2500

//const MENU_ID = "menu-id"

//const paperSizeA4 = [2480, 3508];
// const paperSizeA4 = [595, 842];
//const paperSizeA4 = [1654, 2338]; //200dpi



const browserStorage = sessionStorage;
// const browserStorage = localStorage

const deepClone = (objects) => {
  return _.cloneDeep(objects);
}

let _onRender = () => { }

const App = (props) => {
  const search = useLocation().search;
  // shapesSaveTimer timer:

  let shapesSaveTimer = null;
  let shapesImagesTimer = null;

  const [imagesObj, _updateImages] = useState(() => {
    // getting stored value
    const saved = browserStorage.getItem(LOCALSTORAGE.images);
    const initialValue = JSON.parse(saved);
    return initialValue || {}
  });


  const [fontsObj, _updateFonts] = useState(() => {
    // getting stored value
    const saved = browserStorage.getItem(LOCALSTORAGE.fonts);
    const initialValue = JSON.parse(saved);
    return initialValue || {}
  });



  const getDefaultSettings = () => {
    return {
      guideColumns: 2,
      guideRows: 2,
      backgroundColor: "#ffffff",
      templateName: "exported-template",
      templateId: '',
      templateLanguages: ['en'],
      backgroundLocked: false,
      defaultFontFamily: 'Abel',
      defaultFontStyle: '',
      defaultFontSize: 20,
      display: getDefaultDisplaySettings()
    }
  }

  const getDefaultDisplaySettings = () => {
    return {
      optionalfields: {
        enabled: false,
        options: [],
        overrides: {}
      }
    }
  }


  const [guides, setGuides] = useState(true);
  const [settingsModalOpen, setSettingsOpen] = useState(false);
  const [browseTemplatesModalOpen, setOpenBrowseTemplates] = useState(false);
  const [browseExampleTemplatesModalOpen, setOpenExampleBrowseTemplates] = useState(false);
  const [textBoundaries, showTextBoundaries] = useState(false);
  const selectedLanguageRef = React.useRef('en');
  const [selectedLanguage, setSelectedLanguage] = useState('en');


  const [savedHash, _setSavedHash] = useState(() => {
    // getting stored value

    const initialValue = browserStorage.getItem(LOCALSTORAGE.changeshash);

    return initialValue || ''
  });
  const settingsRef = React.useRef(null);
  const [settings, setSettings] = useState(() => {
    // getting stored value

    const saved = browserStorage.getItem(LOCALSTORAGE.settings);
    const initialValue = JSON.parse(saved);
    if (initialValue && !Array.isArray(initialValue.TemplateLanguages)) {
      const languagesList = (initialValue.TemplateLanguages || 'en').split(',')
      initialValue.templateLanguages = languagesList
    }

    return initialValue || getDefaultSettings()
  });

  const displayDataRef = React.useRef({});
  const [displayData, _updateDisplayData] = useState(() => {
    // getting stored value
    const saved = browserStorage.getItem(LOCALSTORAGE.displaydata)
    const initialValue = JSON.parse(saved) || getDefaultDisplaySettings();
    displayDataRef.current = initialValue
    return initialValue
  });




  const i18nUIRef = React.useRef({});
  const [i18nUI, _updateI18NUI] = useState(() => {
    // getting stored value
    const saved = browserStorage.getItem(LOCALSTORAGE.i18n);
    const initialValue = JSON.parse(saved) || {};
    i18nUIRef.current = i18nObj2UI(initialValue)
    return i18nObj2UI(initialValue)
  });



  // add check?
  const [backendSettings, setBackendSettings] = useState({ type: "loading" })


  const {openFilePicker,  filesContent, loading } = useFilePicker({
    multiple: false,
    accept: ZIPFILENAMES.importAccept,
    readAs: "ArrayBuffer",
    onFilesSuccessfullySelected: ({ plainFiles, filesContent }) => {
      // this callback is called when there were no validation errors
      processImportFiles(filesContent)
    },
  });


  const updateShapesContentDelayed = (timeoutms = 2000) => {

    clearTimeout(shapesImagesTimer)
    shapesImagesTimer = setTimeout(() => {
      updateShapesContent(shapesRef.current)
    }, timeoutms)

  }


  // sensors for @dnd-kit sortable
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 15,
      }
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );


  const [selectedId, _selectShape] = useState(null);
  const [userLoggedIn, setUserLoggedIn] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(null);
  // const [shapes, _setShapes] = useState([]);
  const [shapes, _setShapes] = useState(() => {
    // getting stored value
    const saved = browserStorage.getItem(LOCALSTORAGE.shapes);
    const initialValue = JSON.parse(saved);
    updateShapesContentDelayed(100);
    return initialValue || [];
  }
  );

  // const templateIdRef = React.createRef();
  // const [shapes, _setShapes] = useState([]);
  const [templateId, _setTemplateId] = useState(() => {
    // getting stored value
    const initialValue = browserStorage.getItem(LOCALSTORAGE.templateid) || '';
    // templateIdRef.current = initialValue
    return initialValue;
  }
  );

  const setTemplateId = (id) => {

    browserStorage.setItem(LOCALSTORAGE.templateid, id)
    // templateIdRef.current = id
    _setTemplateId(id)

  }

  const [uiBlockedState, setUiBlockedState] = useState(false);

  const [selectedColor, setSelectedColor] = useState('#000');
  const [selectedStrokeColor, setSelectedStrokeColor] = useState('#000');
  const [pickerColorList, _updatePickerColorList] = useState([]);
  const [selectedOpacity, setSelectedOpacity] = useState(100);
  const [scaleIndicator, setScaleIndicator] = useState(100);
  const [hasQrCode, setQrCode] = useState(false);
  const [mandatoryFields, updateMandatoryFields] = useState(MANDATORYFIELDS);

  //accordion
  const [accordionExpanded, setAccordionExpanded] = React.useState('panel1');
  const [selectedTab, setTabValue] = React.useState(1);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setAccordionExpanded(isExpanded ? panel : false);
  };
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const [, updateState] = React.useState();

  const stageEl = React.useRef(null);
  const backendSettingsRef = React.useRef({});
  // const i18nUIRef = React.useRef({});
  const selectedIdRef = React.useRef(selectedId);
  const shapesRef = React.useRef(shapes);
  const imagesRef = React.useRef(imagesObj);
  const fontsRef = React.useRef(fontsObj);

  const layerEl = React.createRef();
  // const dynamicLayerEl = React.createRef();
  // const fileUploadEl = React.createRef();

  // overriding setters for window events. State is not available if we dont use ref
  const selectShape = id => {
    selectedIdRef.current = id;
    _selectShape(id);

    // check the tab
    if (id !== null) {
      if (getTypeById(id) !== 'textelement' && selectedTab === 0) {
        setTabValue(1);
      }
    }

    //forceUpdate();
  };
  const updateI18NUI = (i18nui) => {
    browserStorage.setItem(LOCALSTORAGE.i18n, JSON.stringify(i18nUI2Obj(i18nui)));

    _updateI18NUI(i18nui);
    i18nUIRef.current = i18nui;
  }

  const updateDisplayData = (data) => {
    browserStorage.setItem(LOCALSTORAGE.displayData, JSON.stringify(data));
    // console.log("inUpdata")
    // console.log(JSON.stringify(data))
    _updateDisplayData(data);
    displayDataRef.current = data;
    // console.log(JSON.stringify(displayData))
    changeSettings(data, 'display')
  }


  const updateImages = (imgs) => {
    browserStorage.setItem(LOCALSTORAGE.images, JSON.stringify(imgs));

    _updateImages(imgs);
    imagesRef.current = imgs;
    updateShapesContentDelayed(100)

  }


  const updateFonts = (fonts) => {
    browserStorage.setItem(LOCALSTORAGE.fonts, JSON.stringify(fonts));

    _updateFonts(fonts);
    fontsRef.current = fonts;
    // updateShapesContentDelayed()

  }

  const getTestInputData = (lang = 'en') => {
    const dyntext = { ...DYNAMICFIELDDEFAULTTEXT['default'] }
    return dyntext
  }

  const updateMandatoryState = (shapes) => {
    // lets default to "false"
    let newMandatoryFieldStatus = { ...MANDATORYFIELDS }

    // keys to check
    let mandatoryKeys = Object.keys(MANDATORYFIELDS)

    // const lenght to shapes as the size does not change on the loop
    const shapesLength = shapes.length

    shapesloop:
    for (let i = 0; i < shapesLength; i++) {
      // console.log(shapes[i].id)
      for (let kindex = 0; kindex < mandatoryKeys.length; kindex++) {
        const k = mandatoryKeys[kindex]
        if (shapes[i].dynamicText === k) {
          // found match from shapes, set mandatory status to true
          newMandatoryFieldStatus[k] = true;
          mandatoryKeys.splice(kindex, 1)

          // jump to main loop, because we found a key in this element
          continue shapesloop
        }
      }
    }

    updateMandatoryFields(newMandatoryFieldStatus)
  }
  const setShapes = shapes => {
    shapesRef.current = shapes;
    // console.log(shapes[8]);

    saveShapesDelayed(shapes, 2000)
    //browserStorage.setItem(LOCALSTORAGE.shapes, JSON.stringify(shapes));
    updateMandatoryState(shapes)

    _setShapes(shapes);
  };

  const saveShapesDelayed = (shapes, timeoutms = 2000) => {
    clearTimeout(shapesSaveTimer)
    shapesSaveTimer = setTimeout(() => {
      //TODO: add stack and undo?
      const sl = shapes.length
      let shapesFiltered = deepClone(shapes)
      let images = deepClone(imagesObj)
      for (let i = 0; i < sl; i++) {
        const entry = shapesFiltered[i]
        if (entry["name"] && images[entry["name"]]) {
          delete (entry["content"])
        }
        // console.log(`shape: ${entry}`)
      }

      browserStorage.setItem(LOCALSTORAGE.shapes, JSON.stringify(shapesFiltered));
    }, timeoutms)

  }



  const updateShapesContent = (origShape) => {
    let shapes = deepClone(origShape)
    // console.log("update called")
    const sl = shapes.length
    // let shapesFiltered = []
    const imagesLocal = deepClone(imagesRef.current)
    for (let i = 0; i < sl; i++) {
      const entry = shapes[i]

      if (entry.type === 'image' && entry["name"]) {
        // localStrorage has image
        // console.log(entry['name'])
        if (imagesLocal[entry.name] && imagesLocal[entry.name].content) {

          let image = imagesLocal[entry.name]

          entry.content = image.content

        } else {
          // TODO: Delete image from shapes?
          // entry.content = UI.missingImageDataUrl

        }
      }
      if (!entry.content) {
        // handle missing image from localstorage
        entry.content = UI.missingImageDataUrl
      }
      // console.log(`shape:`)
      // console.log(entry)

    }
    setShapes(shapes)

  }

  const setSavedHash = (h) => {
    _setSavedHash(h)
    browserStorage.setItem(LOCALSTORAGE.changeshash, h)
  }

  const setRenderer = (renderer) => {
    _onRender = renderer
  }


  // const setSelectedLanguage = (lang) => {
  //   selectedLanguageRef.current = lang
  //   _setSelectedLanguage(lang)
  // }

  const asyncEffectSteps = async () => {
    await loadBackendSettings();

    WebFont.load({

      custom: {

        // families: ["Montserrat","Oswald","PlayfairDisplay","Poppins"],
        families: [],
        urls: ["https://pdfmedia.givito.tech/static/css/all.css", "/designer/fonts/custom.css"]
      }
      // google: {
      //   families: ['Droid Sans', 'Chilanka']
      // }
    });


    let templateIdUrl = new URLSearchParams(search).get("id");
    if (templateIdUrl && templateId !== templateIdUrl) {
      let templateIdChanged = (templateId !== '')

      setTemplateId(templateIdUrl)
      changeSettings(templateIdUrl, "templateId")

      // Load template based in ID
      // TODO: Add checks if it overrides unsaved data
      let id = templateIdUrl
      if (id !== '' && (backendSettingsRef.current.features?.load || false)) {
        if (templateIdChanged) {
          // TODO: handle more intelegently
          // alert("Lost changes")
        }
        // console.log(backendSettingsRef.current)
        let url = backendSettingsRef.current.endpoints.load ?? ''
        url = url.replace('{id}', id)
        let clearTemplate = new URLSearchParams(search).get("clearTemplate")
        let currentTime = Math.floor(Date.now() / 1000)
        if (clearTemplate && clearTemplate > currentTime - 20) {
          console.warn("template not loaded, clearTemplate timout 20 secods")
        } else {
          await loadTemplate(url)
        }
        setSavedHash(calculateChangesHash())
      }
      // console.log(templateId)
    }

  }


  const loadBackendSettings = async () => {
    // FIX, does not update backend settings for rendere
    const backendSettingsLoad = await fetchBackendSettings()
    setBackendSettings(backendSettingsLoad)
    backendSettingsRef.current = backendSettingsLoad

    // TODO load template with get query id

    let languagesList = ['en']
    if (backendSettingsLoad.languages) {
      languagesList = backendSettingsLoad.languages
    }
    let overrideTemplateLanguages = new URLSearchParams(search).get("languages")
    if (overrideTemplateLanguages) {
      languagesList = overrideTemplateLanguages.split(',')
    }

    changeSettings(languagesList, "templateLanguages");
    let lang = languagesList[0] ?? 'en'
    if (backendSettingsLoad.defaultlang && languagesList.includes(backendSettingsLoad.defaultlang)) {
      lang = backendSettingsLoad.defaultlang
    }
    let overrideLang = new URLSearchParams(search).get("lang")
    if (languagesList.includes(overrideLang)) {
      lang = overrideLang
    }

    let displayData = {}
    if (backendSettingsLoad.hasOwnProperty("display")) {
      displayData = {
        ...displayData,
        ...backendSettingsLoad.display
      }
    }

    // console.log(JSON.stringify(backendSettingsLoad.display))
    if (!displayData.hasOwnProperty('optionalfields')) {
      // console.log("WhyHERE")
      displayData['optionalfields'] = {
        enabled: false,
        options: [],
        overrides: {}
      }
    }

    if (!Array.isArray(displayData.optionalfields["options"])) {
      displayData.optionalfields["options"] = []
    }

    if (!displayData.optionalfields.hasOwnProperty["enabled"]) {
      displayData.optionalfields['enabled'] = (displayData.optionalfields.options.length > 0)
    }

    if (displayData.optionalfields['enabled'] && !(displayData.optionalfields.options.length > 0)) {
      displayData.optionalfields['enabled'] = false
    }

    let enableOptionalFields = []
    let enableOptionalFieldsString = new URLSearchParams(search).get("optionalfields")
    if (enableOptionalFieldsString) {
      enableOptionalFields = enableOptionalFieldsString.split(',')
    }


    if (enableOptionalFields.length > 0) {
      displayData.optionalfields['enabled'] = true
      displayData.optionalfields['options'] = [
        ...displayData.optionalfields.options,
        ...enableOptionalFields
      ]
    }

    changeSettings(displayData, 'display')

    displayData.optionalfields['options'] = Array.from(new Set(displayData.optionalfields['options']))
    updateDisplayData(displayData)


    setSelectedLanguage(lang);
    setRenderer(onRenderBrowser)
    // setRenderer(onRenderServer)
    // console.log("on bg settings")
    // console.log(backendSettingsLoad)

    if (backendSettingsLoad.features && !backendSettingsLoad.features.rendererbrowser) {
      setRenderer(onRenderServer)
    }
    // setRenderer(onRenderServer)

  }

  const runAuthChecker = async (url) => {
    // checks if user is logged in
    let intervalTime = 60000;
    let authCheckerTimer = null;

    try {
      const response = await fetch(url);
      if (response.status === 200) {
        try {
          const myJson = await response.json(); //extract JSON from the http response
          console.log(myJson);
        }
        catch {
          console.log('not json');
          setUserLoggedIn(false);
        }

      } else {
        console.log("not a 200");
        setUserLoggedIn(false);
      }
    } catch (err) {
      // catches errors both in fetch and response.json
      console.log(err);
      setUserLoggedIn(false);
    } finally {
      // do it again in 2 seconds
      setTimeout(runAuthChecker, intervalTime, url);
    }
  }

  useEffect(() => {
    asyncEffectSteps();
    // after a new font is loaded, refresh the shapes
    document.fonts.onloadingdone = function (fontFaceSetEvent) {
      // console.log('fontface loaded: ', fontFaceSetEvent.fontfaces);
      forceUpdate();
    };


    //runAuthChecker('/designer/ping');
    addScrollZoomListener();

    const addKeyboardListeners = (e) => {
      let disableKeybindsForTarget = e.target.className.includes('js-disable-keybinds');

      if (e.key === 'Delete') {


        if (selectedIdRef.current != null && !disableKeybindsForTarget) {
          let shindex = shapesRef.current.findIndex(s => s.id === selectedIdRef.current);
          if (shindex !== -1) {

            shapesRef.current.splice(shindex, 1);

            // SEE updateMandatoryState

            if (selectedIdRef.current.type === 'qrcode') {
              setQrCode(false);
            }

            selectShape(null);
            setShapes(shapesRef.current);
          }
          // forceUpdate();
        }
      }
      if (e.key === 'ArrowUp' || e.key === 'ArrowDown' || e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
        if (selectedIdRef.current != null && !disableKeybindsForTarget) {
          let shindex = shapesRef.current.findIndex(s => s.id === selectedIdRef.current);
          if (shindex !== -1) {
            let shape = shapesRef.current[shindex];
            // console.log(shape);
            if (e.key === 'ArrowUp') {
              shape.y = shape.y - 1;
            }
            if (e.key === 'ArrowDown') {
              shape.y = shape.y + 1;
            }
            if (e.key === 'ArrowLeft') {
              shape.x = shape.x - 1;
            }
            if (e.key === 'ArrowRight') {
              shape.x = shape.x + 1;
            }
            shapesRef.current[shindex] = shape;

            setShapes(shapesRef.current);


          }
          // forceUpdate();
        }
      }
    };

    window.addEventListener('keydown', addKeyboardListeners);

  }, []);



  useEffect(() => {

    // Call an async function in your `useEffect`
    // to wait for the promise to resolve and use the data
    // to update the ref
    // console.log(`useEffect bg: ${settings.backgroundColor}`)
    selectedLanguageRef.current = selectedLanguage;
  }, [selectedLanguage]);

  useEffect(() => {

    // Call an async function in your `useEffect`
    // to wait for the promise to resolve and use the data
    // to update the ref
    // console.log(`useEffect bg: ${settings.backgroundColor}`)
    settingsRef.current = settings
  }, [settings]);

  const forceUpdate = React.useCallback(() => updateState({}), []);




  const getRandomInt = max => {
    return Math.floor(Math.random() * Math.floor(max));
  };

  // Shapes base generic function
  const addShape = (funcname) => async (opts = {}) => {
    const optKeys = ["x", "y", "width", "height", "full", "fontsize", "align", "valign", "fontFamily", "langkey", "name", "storageId", "dynelem", "content", "stroke", "strokeWidth", "dependsOnDynamicTextElement"]
    // appear at the center
    let shapeOpts = {
      x: (UI.paperSizeA4[0] / 2) + getRandomInt(50),
      y: (UI.paperSizeA4[1] / 2) + getRandomInt(50),
    }
    if (funcname === 'line') {
      shapeOpts.width = 200;
      shapeOpts.height = 0;
    }

    for (let i = 0; i < optKeys.length; i++) {
      const k = optKeys[i]
      if (opts[k]) {
        shapeOpts[k] = opts[k]
      }
    }
    // const newShape = rectangle()
    // console.log("SHAPE")
    // console.log(shapeOpts)
    const newShape = await shapesHandling[funcname](shapeOpts, settings);
    // console.log("NEWSHAPE")
    // console.log(newShape)
    const shs = deepClone(shapesRef.current);
    // console.log(shs)
    shs.push(newShape);

    setShapes(shs);

    //select by default

    selectShape(newShape.id);
    setSelectedIndex(shs.findIndex(s => s.id === newShape.id));

  }

  // add 'addShapeTyep functions, the code is in shapesHandling.js
  const addRectangle = addShape('rectangle')
  const addImage = (image, opts = {}) => {
    let newOpts = {
      ...image,
      ...opts
    }
    // opts.image = image

    let tmpShape = addShape('image')(newOpts)
    // console.log(tmpShape)

    return tmpShape.then((data) => { updateShapesContentDelayed(100); return data })
  }
  const addQRcode = addShape('qrcode')

  const addCircle = addShape('circle')
  const addLine = addShape('line')
  const addTextElement = addShape('textElement')
  const addDynamicTextElement = addShape('dynamicTextElement')




  const addScrollZoomListener = () => {
    stageEl.current.on('wheel', (e) => {

      e.evt.preventDefault();
      var scaleBy = 1.02;
      var oldScale = stageEl.current.scaleX();

      var pointer = stageEl.current.getPointerPosition();

      var mousePointTo = {
        x: (pointer.x - stageEl.current.x()) / oldScale,
        y: (pointer.y - stageEl.current.y()) / oldScale,
      };

      var newScale =
        e.evt.deltaY > 0 ? oldScale / scaleBy : oldScale * scaleBy;

      stageEl.current.scale({ x: newScale, y: newScale });

      var newPos = {
        x: pointer.x - mousePointTo.x * newScale,
        y: pointer.y - mousePointTo.y * newScale,
      };
      stageEl.current.position(newPos);

      stageEl.current.batchDraw();

      // setting state will cause lagging, so i went around it by adding a timeout. window.test is initialized in index.js
      if (window.test === null) {
        window.test = setTimeout(function () {
          setScaleIndicator(parseInt(newScale * 100));
          window.test = null;
        }, 100);
      }


    });
  }

  const resetZoom = () => {
    let xpos = (window.innerWidth * 0.75) / 4;
    stageEl.current.scale({ x: 1, y: 1 });
    setScaleIndicator(100);
    forceUpdate();
  }

  const zoomClick = (amount) => {
    var oldScale = stageEl.current.scaleX();
    let newScale = oldScale + (amount / 100);

    stageEl.current.scale({ x: newScale, y: newScale });
    stageEl.current.batchDraw();
    setScaleIndicator(parseInt(newScale * 100));
  }


  const onToggleGuides = (e) => {
    setGuides(!guides);
  }

  const onToggleTextBoundaries = (e) => {
    showTextBoundaries(!textBoundaries);
  }

  const getOriginalStageVals = (e) => {
    return {
      x: stageEl.current.x(),
      width: stageEl.current.width(),
      height: stageEl.current.height()
    };
  }

  const openSettings = (e) => {
    setSettingsOpen(true);
  }
  const closeSettings = () => {
    setSettingsOpen(false);
  };


  const openBrowseTemplates = (e) => {
    setOpenBrowseTemplates(true);
  }


  const openExampleBrowseTemplates = (e) => {
    setOpenExampleBrowseTemplates(true);
  }

  const closeBrowseTemplates = () => {
    setOpenBrowseTemplates(false);
  };


  const closeExampleBrowseTemplates = () => {
    setOpenExampleBrowseTemplates(false);
  };


  const onClear = (e) => {
    if (window.confirm('This will clear the template. Are you sure you want to start again?')) {
      // clearTemplate
      browserStorage.clear()

      const searchParams = new URLSearchParams(search);
      searchParams.set("clearTemplate", Date.now() / 1000)

      // forces reload to clear page settings
      window.location.search = searchParams.toString();

    }


  }


  const calcChars = [
    "Q",
    "W",
    "E",
    "R",
    "T",
    "Y",
    "U",
    "I",
    "O",
    "P",
    "A",
    "S",
    "D",
    "F",
    "G",
    "H",
    "J",
    "K",
    "L",
    "Z",
    "X",
    "C",
    "V",
    "B",
    "N",
    "M",
    ".",
    "q",
    "w",
    "e",
    "r",
    "t",
    "y",
    "u",
    "i",
    "o",
    "p",
    "a",
    "s",
    "d",
    "f",
    "g",
    "h",
    "j",
    "k",
    "l",
    "z",
    "x",
    "c",
    "v",
    "b",
    "n",
    "m",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "0",
    "@",
  ];

  const calculateDynamicFields = (shapesArray) => {
    let returnList = []
    const fonthelperCanvas = document.getElementById("fonthelper");
    let ctx = fonthelperCanvas.getContext('2d');
    // console.log(fonthelperCanvas)
    const aSize = shapesArray.length;
    for (let i = 0; i < aSize; i++) {
      const elem = shapesArray[i]
      // console.log(`${elem.type} ${i} ${aSize}`)
      if (elem.type === 'textelement') {
        // console.log("dynReturnData")
        const { type, id, fontSize, fontFamily, width, height, fontStyle, dynamicText, ...rest } = elem
        if (!isNull(dynamicText)) {
          let rows, columns = 0;

          let fontLine = `${fontStyle} ${fontSize}px ${fontFamily}`
          fontLine = fontLine.replace(/\s*normal\s*/, " ")
          ctx.font = fontLine
          // console.log(fontLine)

          let charSum = 0
          const calcCharsLength = calcChars.length
          for (let ci = 0; ci < calcCharsLength; ci++) {
            let t = calcChars[ci]
            const metrics = ctx.measureText(t);
            charSum += metrics.width
          }

          const fWidth = charSum / calcCharsLength

          rows = Math.floor(height / (fontSize * 1.33))
          columns = Math.floor(width / fWidth)
          // console.log(metrics.width)
          // console.log("dynReturnData")
          const dynReturnData = {
            name: dynamicText,
            id,
            // fontSize,
            // height,
            // width,
            rows,
            columns
          }
          // console.log(dynReturnData)
          returnList = [...returnList, dynReturnData]
        }
      }

    }
    return returnList;
  }


  const onExport = async (e) => {
    let isValid = validateMandatoryFields();
    // console.log(shapesRef.current[8])
    // console.log(shapes[8])
    if (isValid) {
      let outputFilename = ZIPFILENAMES.defaultFileName
      if (settings["templateName"]) {
        let templateNameForExport = settings["templateName"]
        templateNameForExport = templateNameForExport.replace(/ /g, "_").toLowerCase()
        outputFilename = `${templateNameForExport}${ZIPFILENAMES.defaultFileExtension}`

      }
      let i18n = i18nUI2Obj(i18nUI)
      const fontsBlob = await gc.shapes2fonts(shapesRef.current, fontsRef.current)

      gc.bundleTemplateZip(shapesRef.current, settings, imagesRef.current, i18n, fontsBlob)
        .then(function (blob) {
          saveAs(blob, outputFilename);
        });

    } else {
      alert('Mandatory fields missing');
    }
  }





  const processImportFiles = (files) => {
    for (let index = 0; index < files.length; index++) {
      const file = files[index]
      const content = file.content
      // console.log(`
      //     ${file.name}
      //     key=${index}
      //     `);
      gc.processImportFile(content).then(data => {
        updateImages(data.images)
        updateFonts(data.fonts)
        updateSettings(data.settings)
        updateI18NUI(i18nObj2UI(data.i18n))
        updateShapesContent(data.shapes)

      })
      //await processImportFile(file.content)
      //
    }
  }

  // @TODO add spinner on load
  const onImport = (e) => {
    openFilePicker()
  }

  const changeTemplate = async (template, handleClose) => {

    if (window.confirm('This will clear your changes and load a premade template. Are you sure?')) {

      changeSettings(template.name, "templateName");
      await loadTemplate(template.url);
      handleClose();
    }


  }



  const loadTemplate = async (url) => {


    try {
      const dataBundle = await fetchTemplateBundle(url)

      const data = await gc.processImportFile(dataBundle)
      updateFonts(data.fonts)
      updateImages(data.images)
      updateSettings(data.settings)
      updateShapesContent(data.shapes)
      updateI18NUI(i18nObj2UI(data.i18n))
      // closeBrowseTemplates()

    } catch (error) {
      alert("problem with template bundle")
      console.error(error);
    }


  }

  const calculateChangesHash = () => {
    const imagesHash = objectHash(browserStorage.getItem(LOCALSTORAGE.images))
    const settingsHash = objectHash(browserStorage.getItem(LOCALSTORAGE.settings))
    const i18nHash = objectHash(browserStorage.getItem(LOCALSTORAGE.i18n))
    const shapesHash = objectHash(browserStorage.getItem(LOCALSTORAGE.shapes))
    const h = `img:${imagesHash},set:${settingsHash},is8n:${i18nHash},shapes:${shapesHash}`
    return h
  }

  const unSavedChanges = () => {
    const h = calculateChangesHash()
    return (savedHash !== h)
  }

  const onLoad = async (e) => {

    if (!templateId) {
      alert('Not loaded from backend, cannot reload');
      return ''
    }
    //TODO: backend

    let id = templateId


    let url = backendSettingsRef.current.endpoints.load ?? ''
    url = url.replace('{id}', id)
    await loadTemplate(url)
    setSavedHash(calculateChangesHash())

  }



  const onSave = async (e) => {
    let isValid = validateMandatoryFields();
    if (!templateId) {
      alert('Not loaded from backend, cannot save');
      return ''
    }
    if (isValid) {

      let i18n = i18nUI2Obj(i18nUI)
      const fontsBlob = await gc.shapes2fonts(shapesRef.current, fontsRef.current)
      gc.bundleTemplateZip(shapesRef.current, settings, imagesRef.current, i18n, fontsBlob)
        .then(function (blob) {

          let id = templateId
          let url = backendSettingsRef.current.endpoints.save ?? ''
          url = url.replace('{id}', id)
          const dynamicTextList = calculateDynamicFields(shapesRef.current)
          const extraFields = [
            {
              name: "dynamicFieldSizes",
              data: dynamicTextList
            }
          ]
          sendToStorage(url, blob, extraFields)
            .then(data => {
              setSavedHash(calculateChangesHash())
              // TODO: handle save
              alert("Saved successfully");
            })
            .catch((error) => {
              alert("Saving failed.");
              console.error('Error:', error);
            });
        });
    } else {
      alert('Mandatory fields missing');
    }


  }

  // const removeGuidesBeforeSave = (stage) => {

  //   let result = stage.children.filter((child) => {
  //     return child.attrs.className != 'guides';
  //   });
  //   stage.children = result;

  //   return stage;
  // }

  const validateMandatoryFields = () => {

    let isValid = true;
    Object.values(mandatoryFields).forEach(val => {
      if (val === false) {
        isValid = false;
      }
    });
    return isValid;
  }


  const onRenderBrowser = async (e) => {
    let id = 'current'
    // let url = (backendSettingsRef.current.endpoints ?? {}).render ?? 'wtf'
    // url = url.replace('{id}', id)
    const i18nRef = i18nUI2Obj(i18nUIRef.current) ?? {}

    const fontsBlob = await gc.shapes2fonts(shapesRef.current, fontsRef.current)
    let buf = await gc.bundleTemplateZip(shapesRef.current, settingsRef.current, imagesRef.current, i18nRef, fontsBlob)

    let templateData = await gc.processImportFile(buf)
    // console.log(templateData)
    // let shapesJSON = await contents.files[ZIPFILENAMES.template].async('string')
    let shapesData = templateData.shapes ?? []
    let settingsData = templateData.settings ?? {}
    let i18n = templateData.i18n ?? {}
    let images = templateData.images ?? {}
    let fonts = templateData.fonts ?? {}
    // console.log()
    // console.log(fonts)
    // try {
    //     let fileI18N = await contents.files[ZIPFILENAMES.i18n].async('string')
    //     let tmpI18N = JSON.parse(fileI18N)
    //     i18n = tmpI18N
    // } catch (err) {
    // }

    // console.log(shapesData);return;

    if (shapesData) {

      let defaultSettings = {
        paperSize: gc.paperSizeA4,
        backgroundColor: '#FFFFFF'
      }
      let lang = selectedLanguageRef.current || 'en'


      const canvasData = {
        lang: lang,
        settings: {
          ...defaultSettings,
          ...settingsData,
        },
        i18n: i18n,
        shapes: [
          ...shapesData
        ],
        images: images,
        fonts: fonts

      }

      const doc = new gc.PDFDocument({
        size: canvasData.settings.paperSize
      });

      const stream = doc.pipe(gc.blobStream());


      doc.on('error', (err) => {
        console.log('Error in doc stream', err);
      });

      let gcstext = getTestInputData(lang)

      try {
        let pdf = await gc.createPDF(doc, canvasData, 'assets', gcstext);
      } catch (err) {
        console.log(err.message)
        console.error(err)
      }
      doc.end();



      doc.on('end', function () {
        console.log(`doc is finished: ${canvasData.settings.templateName || "demo"}`);
      });
      doc.on('close', function () {
        console.log(`doc is closed: ${canvasData.settings.templateName || "demo"}`);
      });
      stream.on('finish', function () {
        // get a blob you can do whatever you like with
        const blob = stream.toBlob('application/pdf');
        saveAs(blob, "demoGC.pdf");
        // or get a blob URL for display in the browser
        // const url = stream.toBlobURL('application/pdf');
        // iframe.src = url;
      });
    } else {
      // return error
    }



  }

  // const onRenderBrowser2 = async (e) => {
  //   const shapesData = deepClone(shapesRef.current) ?? []
  //   const settingsJSON = browserStorage.getItem(LOCALSTORAGE.settings);
  //   const settings = JSON.parse(settingsJSON) ?? {}
  //   if (shapesData) {
  //     const renderImages = deepClone(imagesRef.current)
  //     const i18n = i18nUI2Obj(i18nUIRef.current) ?? {}
  //     // console.log("HERE")
  //     // console.log(i18n)
  //     // console.log(i18nUI)
  //     const renderFonts = {}
  //     let defaultSettings = {
  //       paperSize: gc.paperSizeA4,
  //       backgroundColor: '#FFFFFF'
  //     }
  //     let lang = selectedLanguageRef.current
  //     const canvasData = {
  //       lang: lang ?? 'en',
  //       settings: {
  //         ...defaultSettings,
  //         ...settings,
  //       },
  //       i18n: i18n,
  //       shapes: shapesData,
  //       images: renderImages,
  //       fonts: renderFonts,
  //     }
  //     // console.log(canvasData)
  //     const doc = new gc.PDFDocument({
  //       size: canvasData.settings.paperSize
  //     });
  //     const stream = doc.pipe(gc.blobStream());
  //     doc.on('error', (err) => {
  //       console.log('Error in doc stream', err);
  //     });
  //     let gcstext = getTestInputData(lang)
  //     // console.log(gcstext)
  //     try {
  //       let pdf = await gc.createPDF(doc, canvasData, 'assets', gcstext);
  //     } catch (err) {
  //       console.log(err.message)
  //       console.error(err)
  //     }
  //     doc.end();
  //     doc.on('end', function () {
  //       console.log(`doc is finished: ${canvasData.settings.templateName || "demo"}`);
  //     });
  //     doc.on('close', function () {
  //       console.log(`doc is closed: ${canvasData.settings.templateName || "demo"}`);
  //     });
  //     stream.on('finish', function () {
  //       // get a blob you can do whatever you like with
  //       const blob = stream.toBlob('application/pdf');
  //       saveAs(blob, "demoGC.pdf");
  //       // or get a blob URL for display in the browser
  //       // const url = stream.toBlobURL('application/pdf');
  //       // iframe.src = url;
  //     });
  //   } else {
  //     // return error
  //   }
  // }

  const onRenderServer = async (e) => {
    let id = 'current'
    let lang = selectedLanguageRef.current || 'en'
    let url = (backendSettingsRef.current.endpoints ?? {}).render ?? 'wtf'
    url = url.replace('{id}', id)
    url = url.replace('{lang}', lang)
    let i18n = i18nUI2Obj(i18nUIRef.current)
    const fontsBlob = await gc.shapes2fonts(shapesRef.current, fontsRef.current)
    gc.bundleTemplateZip(shapesRef.current, settings, imagesRef.current, i18n, fontsBlob)
      .then(function (blob) {
        sendToRender(url, blob)
          .then(data => {
            saveAs(data, "demo.pdf")
          })
          .catch((error) => {
            console.error('Error:', error);
          });
      })

  }
  // let _onRender = onRenderBrowser
  const onRender = () => {
    return _onRender()
  }


  const updateSettings = (newSettings) => {
    const backendSettingsLocal = backendSettingsRef.current
    let languagesList = ['en']
    if (backendSettingsLocal.languages) {
      languagesList = backendSettingsLocal.languages
    }
    let overrideTemplateLanguages = new URLSearchParams(search).get("languages")
    if (overrideTemplateLanguages) {
      languagesList = overrideTemplateLanguages.split(',')
    }

    const defaultSettings = getDefaultSettings()
    let settingsObj = { ...defaultSettings, ...newSettings }
    if (!Array.isArray(settingsObj.templateLanguages)) {
      const languagesListNew = (settingsObj.templateLanguages || 'en').split(',')
      settingsObj.templateLanguages = languagesListNew
    }
    settingsObj.templateLanguages = [...new Set([...settingsObj.templateLanguages, ...languagesList])]
    // settingsObj.templateLanguages = [... new Set([...settingsObj.templateLanguages, languagesList])]
    browserStorage.setItem(LOCALSTORAGE.settings, JSON.stringify(settingsObj));

    if (templateId) {
      settingsObj['templateId'] = templateId;
    }
    // console.log(`updateSettings old bg: ${settings.backgroundColor}`)
    // console.log(`updateSettings new bg: ${settingsObj.backgroundColor}`)
    // settingsObj['display'] = displayData ?? {}
    // console.log("From browserStorage")

    settingsObj["display"] = {
      optionalfields: {
        enabled: false,
        options: []
      }
    }
    const activeDisplayData = displayDataRef.current

    if (activeDisplayData?.hasOwnProperty("optionalfields")) {
      settingsObj["display"] = activeDisplayData
      if (settingsObj["display"].optionalfields.hasOwnProperty("options")) {
        settingsObj.display.optionalfields.options = Array.from(new Set(settingsObj.display.optionalfields.options))
      }
    }
    // console.log(JSON.stringify(activeDisplayData))
    setSettings(settingsObj);

  }


  const changeSettings = (e, setting) => {
    let settingsObj = Object.assign({}, settings);
    switch (setting) {
      case 'display':
        settingsObj[setting] = e;
        // console.log("InChangeSettings")
        // console.log(JSON.stringify(e))
        break;
      case 'backgroundColor':
        settingsObj[setting] = e;
        break;
      case 'templateName':
        settingsObj[setting] = e;
        break;
      case 'templateId':

        settingsObj[setting] = e;


        break;
      case 'templateLanguages':
        settingsObj[setting] = e;
        break;
      case 'backgroundLocked':
        settingsObj[setting] = e;
        break;

      case 'defaultFontMulti':
        settingsObj['defaultFontFamily'] = e.label
        if (e.hasOwnProperty("data-fonturl") && e["data-fonturl"].length > 0) {
          settingsObj['defaultFontUrl'] = e["data-fonturl"]
          let fstyle = settingsObj.defaultFontStyle ?? ''
          fstyle = fstyle.replace(/^(?:single_)?/, 'single_')
          settingsObj['defaultFontStyle'] = fstyle
        } else {
          delete (settingsObj['defaultFontUrl'])
          let fstyle = settingsObj.defaultFontStyle ?? ''
          fstyle = fstyle.replace(/^(?:single_)?/, '')
          settingsObj['defaultFontStyle'] = fstyle
        }
        break
      case 'defaultFontFamily':
        settingsObj[setting] = e;
        break;
      case 'defaultFontUrl':
        settingsObj[setting] = e;
        break;
      case 'defaultFontSize':
        settingsObj[setting] = e;
        break;
      case 'defaultFontStyle':
        settingsObj[setting] = e;
        break;
      default:
        settingsObj[setting] = e.target.value;
    }

    updateSettings(settingsObj);



  }

  const changeObjectCooord = (dir) => (e) => {
    let newCoord = parseInt(e.target.value) || 0;
    const shs = deepClone(shapes);
    shs.forEach(element => {
      if (element.id === selectedId) {
        // line needs to be handled differently
        element[dir] = newCoord;

        if (element.type === 'line') {
          if (element['rotation'] === undefined) {
            element.x = element.points[0];
            element.y = element.points[1];
            element.width = element.points[2] - element.points[0];
            element.height = element.points[3] - element.points[1];
          }
          if (dir === 'rotation') {
            const lineLength = Math.hypot(element.width, element.height)
            const newW = Math.round(lineLength * Math.cos(newCoord * Math.PI / 180) * 100000) / 100000;
            const newH = Math.round(lineLength * Math.sin(newCoord * Math.PI / 180) * 100000) / 100000;
            // console.log(`${ newCoord }, ${lineLength} ${newW} , ${newH}`)
            element.width = newW
            element.height = newH
          } else {
            element.rotation = Math.round(Math.atan2(element.height, element.width) * 180 / Math.PI * 100000) / 100000
          }
          element.points[0] = element.x;
          element.points[1] = element.y;
          element.points[2] = element.x + element.width
          element.points[3] = element.y + element.height

        }

      }
    });
    setShapes(shs);
  }

  const changeObjectX = changeObjectCooord('x')
  const changeObjectY = changeObjectCooord('y')
  const changeObjectWidth = changeObjectCooord('width')
  const changeObjectHeight = changeObjectCooord('height')
  const changeObjectRotation = changeObjectCooord('rotation')

  const changeZindex = (direction) => {
    const shs = deepClone(shapes);;
    let shapeIndex = shs.findIndex(s => s.id === selectedId);
    let currentShape = shs.filter((s) => { return s.id === selectedId });
    shs.splice(shapeIndex, 1);
    if (direction === 'up') {
      shs.push(...currentShape);
    }
    else {
      shs.unshift(...currentShape);
    }
    setShapes(shs);
    // forceUpdate();
  }

  const RenderGuides = (e) => {
    let paperWidth = UI.paperSizeA4[0];
    let paperHeight = UI.paperSizeA4[1];

    let guidesJSX = [];
    if (guides) {
      let guideCols = [];
      for (let col = 1; col < settings.guideColumns; col++) {
        let _x = paperWidth / (settings.guideColumns / col)
        guideCols.push(<Line key={"col" + col} x={_x} points={[0, 0, 0, paperHeight]} stroke="#a2cdb9" listening={false} ></Line>);
      }
      let guideRows = [];
      for (let row = 1; row < settings.guideRows; row++) {
        let _y = paperHeight / (settings.guideRows / row);
        guideRows.push(<Line key={"row" + row} y={_y} points={[0, 0, paperWidth, 0,]} stroke="#a2cdb9" listening={false} ></Line>);
      }

      let paperSizeText = "Page size " + paperWidth + " x " + paperHeight + " px";


      guidesJSX = <Layer className="guides">
        <Rect x={0} y={-40} width={155} height={30} fill="#cccccc" cornerRadius={[5, 5, 5, 5]}></Rect>
        <KonvaText x={10} y={-30} text={paperSizeText} fontFamily="Poppins" />

        <Rect x={-390} y={0} width={380} height={230} fill="#cccccc" cornerRadius={[5, 5, 5, 5]}></Rect>
        <KonvaText x={-380} y={10} width={360} text='Please note!' fontFamily="Poppins" fontStyle="bold" />
        <KonvaText x={-380} y={30} width={5} text='&bull;' fontFamily="Poppins" />
        <KonvaText x={-370} y={30} width={360} text='Use Gift Card Designer in Chrome browser for optimal performance' fontFamily="Poppins" />
        <KonvaText x={-380} y={60} width={5} text='&bull;' fontFamily="Poppins" />
        <KonvaText x={-370} y={60} width={360} text='Always use the Preview PDF button to check your template before saving' fontFamily="Poppins" />
        <KonvaText x={-380} y={90} width={5} text='&bull;' fontFamily="Poppins" />
        <KonvaText x={-370} y={90} width={360} text='Important! Remove all images, text or other objects that are not inside the gift card area before saving' fontFamily="Poppins" />
        <KonvaText x={-380} y={120} width={5} text='&bull;' fontFamily="Poppins" />
        <KonvaText x={-370} y={120} width={360} text='Remove all redundant images from the image bank when the template is finished, Gift Card Editor is not meant for storing large image files' fontFamily="Poppins" />
        <KonvaText x={-380} y={165} width={5} text='&bull;' fontFamily="Poppins" />
        <KonvaText x={-370} y={165} width={360} text='You can zoom in and out with the mouse wheel' fontFamily="Poppins" />
        <KonvaText x={-380} y={185} width={5} text='&bull;' fontFamily="Poppins" />
        <KonvaText x={-370} y={185} width={360} text='If you change the size of the the text boxes please remember to preview your products with real data to see if all text is displayed in the gift card.' fontFamily="Poppins" />


        {guideCols}
        {guideRows}
      </Layer>;
    }
    return guidesJSX;
  }

  const RenderBoundaries = (e) => {
    let paperWidth = UI.paperSizeA4[0];
    let paperHeight = UI.paperSizeA4[1];


    let boundaries = <Layer className="guides">
      <Rect className="paper"
        x={0}
        y={0}
        width={paperWidth}
        height={paperHeight}
        fill="#FFFFFF"
      ></Rect>
      <Line x={0} points={[0, 0, 0, paperHeight]} stroke="#ccc" ></Line>
      <Line y={0} points={[0, 0, paperWidth, 0]} stroke="#ccc" ></Line>
      <Line x={paperWidth} points={[0, 0, 0, paperHeight]} stroke="#ccc" ></Line>
      <Line y={paperHeight} points={[0, 0, paperWidth, 0]} stroke="#ccc" ></Line>
    </Layer>;

    return boundaries;
  }

  const updatePickerColorList = (color) => {
    let colorList = deepClone(pickerColorList);

    let colorIndex = colorList.indexOf(color);
    if (colorIndex != -1) {
      //if same color is found, prevent list from having same color multiple times.
      colorList.splice(colorIndex, 1);
    }
    colorList.unshift(color);

    if (colorList.length > 8) {
      colorList.pop();
    }
    _updatePickerColorList(colorList);
  }

  const handleColorChange = (color) => {
    setSelectedColor(color.hex);
    updatePickerColorList(color.hex);

    const shs = deepClone(shapes);
    shs.forEach(element => {
      if (element.id === selectedId) {
        if (getTypeById(selectedId) === 'rect' || getTypeById(selectedId) === 'circ') {
          element.fill = color.hex;
        }
        if (getTypeById(selectedId) === 'textelement') {
          element.fill = color.hex;
        }
      }
    });
    setShapes(shs);
  }
  const handleStrokeColorChange = (color) => {
    setSelectedStrokeColor(color.hex);
    updatePickerColorList(color.hex);

    const shs = deepClone(shapes);
    shs.forEach(element => {
      if (element.id === selectedId) {
        if (getTypeById(selectedId) === 'rect' || getTypeById(selectedId) === 'circ' || getTypeById(selectedId) === 'line') {
          element.stroke = color.hex;
        }
        if (getTypeById(selectedId) === 'textelement') {
          element.stroke = color.hex;
        }
      }
    });
    setShapes(shs);
  }
  const handleStrokeWidthChange = (e) => {
    let newStrokeWidth = parseInt(e.target.value) || 0;
    const shs = deepClone(shapes);
    shs.forEach(element => {
      if (element.id === selectedId) {
        element.strokeWidth = newStrokeWidth;
      }
    });
    setShapes(shs);
  }

  const handleTextMultiline = (e) => {
    const shs = deepClone(shapes);
    shs.forEach(element => {
      if (element.id === selectedId) {
        const multiline = (element.multiline === undefined || element.multiline)
        element.multiline = !multiline
      }
    });
    setShapes(shs);
  }

  const handleHideIfNoValue = (e) => {
    const shs = deepClone(shapes);
    shs.forEach(element => {
      if (element.id === selectedId) {

          const isChecked = element.dependsOnDynamicTextElement && element.dependsOnDynamicTextElement?.name === "valueText";
          if (isChecked) {
            delete element.dependsOnDynamicTextElement;
          }
          else {
            element.dependsOnDynamicTextElement = {name: "valueText", action: "show"}
          }
      }
    });
    setShapes(shs);
  }

  const handleTextUppercase = (e) => {
    const shs = deepClone(shapes);
    shs.forEach(element => {
      if (element.id === selectedId) {
        const textTransform = element.textTransform ? element.textTransform : 'none'
        element.textTransform = textTransform === 'upperCase' ? 'none' : 'upperCase';
      }
    });
    setShapes(shs);
  }


  const handleTextLowercase = (e) => {
    const shs = deepClone(shapes);
    shs.forEach(element => {
      if (element.id === selectedId) {
        const textTransform = element.textTransform ? element.textTransform : 'none'
        element.textTransform = textTransform === 'lowerCase' ? 'none' : 'lowerCase';
      }
    });
    setShapes(shs);
  }

  const handleTextTrim = (e) => {
    const shs = deepClone(shapes);
    shs.forEach(element => {
      if (element.id === selectedId) {
        const textTransform = element.textTransform ? element.textTransform : 'none'
        element.textTransform = textTransform === 'trim' ? 'none' : 'trim';
      }
    });
    setShapes(shs);
  }
  const handleOpacityChange = (e, newValue) => {
    setSelectedOpacity(newValue);


    let opacity = newValue == 0 ? 0 : newValue / 100;

    const shs = deepClone(shapes);
    shs.forEach(element => {
      if (element.id === selectedId) {

        if (getTypeById(selectedId) === 'rect' || getTypeById(selectedId) === 'circ' || getTypeById(selectedId) === 'textelement' || getTypeById(selectedId) === 'image') {
          element.opacity = opacity;
        }
      }
    });
    setShapes(shs);
  }

  //deprecated
  const changeTextForElement = (e) => {
    const shs = deepClone(shapes);

    shs.forEach(element => {
      if (element.id === selectedId) {
        element.text = e.target.value;

      }
    });
    setShapes(shs);
  }

  const changeTranslationForElement = (e, lang, id) => {
    // console.log(lang);
    let locali18n = deepClone(i18nUI)
    if (typeof locali18n[id] === 'undefined') {
      locali18n[id] = {}
      // for (let i = 0; i < settings.templateLanguages.length; i++) {
      //   const boxlang = settings.templateLanguages[i]
      //   locali18n[id][boxlang] = `untranslated: ${boxlang}`
      // }
    }
    locali18n[id][lang] = e.target.value;
    // forceUpdate();
    //to get the shapes to refresh, also update shapes, but only if changing text for selected language
    if (selectedLanguage === lang) {
      changeTextForElement(e);
    }
    updateI18NUI(locali18n)
    // console.log(i18nUI);
  }

  const changeTextFont = async (newValue) => {

    if (newValue) {
      let font = newValue.label;
      // console.log(newValue)
      // jostain syystä asettaa fontin vasta kun toisen kerran valitsee..
      const shs = deepClone(shapes);
      shs.forEach(element => {
        if (element.id === selectedId) {
          element.fontFamily = font;

          if (newValue.hasOwnProperty("data-fonturl") && newValue["data-fonturl"].length > 0) {
            element.fontUrl = newValue["data-fonturl"]
            let fstyle = element.fontStyle ?? ''
            element.fontStyle = fstyle.replace(/^(?:single_)?/, 'single_')
          } else {
            delete (element.fontUrl)
            let fstyle = element.fontStyle ?? ''
            element.fontStyle = fstyle.replace(/^(?:single_)?/, '')
          }

          // if (newValue)
        }
      });

      setShapes(shs);
      forceUpdate();

    }


  }

  const changeFontSize = (e) => {
    let newFontSize = parseInt(e.target.value) || 0;
    const shs = deepClone(shapes);
    shs.forEach(element => {
      if (element.id === selectedId) {
        element.fontSize = newFontSize;
      }
    });
    setShapes(shs);
  }
  const toggleBold = (e) => {

    let computedWeight;
    let font;
    const shs = deepClone(shapes);
    shs.forEach(element => {
      if (element.id === selectedId) {
        font = element.fontFamily;
        if (element.fontStyle) {
          let fontStyleArr = element.fontStyle.split(' ');

          if (fontStyleArr[0] === 'bold') {
            fontStyleArr[0] = 'normal';
          }
          else if (fontStyleArr[0] === 'normal' || typeof fontStyleArr[0] === 'undefined') {
            fontStyleArr[0] = 'bold';
          }
          element.fontStyle = fontStyleArr.join(' ');
          // computedWeight = element.fontStyle;
        }
        else {
          element.fontStyle = 'bold';
          // computedWeight = element.fontStyle;
        }
      }
    });
    setShapes(shs);

  }

  const toggleItalic = (e) => {

    const shs = deepClone(shapes);
    shs.forEach(element => {
      if (element.id === selectedId) {
        if (element.fontStyle) {
          let fontStyleArr = element.fontStyle.split(' ');

          if (fontStyleArr[1] === 'italic') {
            fontStyleArr[1] = '';
          }
          else if (fontStyleArr[1] === '' || typeof fontStyleArr[1] === 'undefined') {
            fontStyleArr[1] = 'italic';
          }
          element.fontStyle = fontStyleArr.join(' ');
        }
        else {
          element.fontStyle = 'normal italic';
        }
      }

    });
    setShapes(shs);
  }

  const toggleAlign = (alignment) => {
    const shs = deepClone(shapes);;
    shs.forEach(element => {
      if (element.id === selectedId) {
        element.align = alignment;
      }
    });
    setShapes(shs);
  }

  const toggleVerticalAlign = (alignment) => {
    const shs = deepClone(shapes);;
    shs.forEach(element => {
      if (element.id === selectedId) {
        element.verticalAlign = alignment;
      }
    });
    setShapes(shs);
  }

  const getTypeById = (id) => {

    if (id.includes('rect')) {
      return 'rect';
    }
    if (id.includes('circ')) {
      return 'circ';
    }
    if (id.includes('line')) {
      return 'line';
    }
    if (id.includes('textelement')) {
      return 'textelement';
    }

    if (id.includes('image')) {
      return 'image';
    }
    if (id.includes('qrcode')) {
      return 'qr';
    }
    return null;
  }


  const shapeIsValid = (attrs) => {
    let re = true;
    if (attrs.height < 0) {
      alert('Height cannot be smaller than zero');
      re = false;
    }
    if (attrs.width < 0) {
      alert('Width cannot be smaller than zero');
      re = false;
    }
    return re;
  }


  const RenderShapes = () => {
    let toRender = shapes.map((shape, i) => {

      if (shape.type === 'rect') {
        return (
          <Rectangle
            key={i}
            shapeProps={shape}
            isSelected={shape.id === selectedId}
            onSelect={() => {
              selectShape(shape.id);
              setSelectedIndex(shapes.findIndex(s => s.id === shape.id));
            }}
            onChange={newAttrs => {
              if (!shapeIsValid(newAttrs)) {
                return false;
              }
              const shs = deepClone(shapes);

              // shs[i] = newAttrs;
              shs[i] = {
                ...shape,
                ...newAttrs
              }
              setShapes(shs);
            }}
          />
        );
      }

      if (shape.type === 'textelement') {

        const defaultText = shape.dynamicText ?? `untranslated: ${selectedLanguage}`
        let langkey = shape.langkey ?? shape.id
        let translatedText = defaultText
        if (i18nUI[langkey]) {
          if (i18nUI[langkey][selectedLanguage] && i18nUI[langkey][selectedLanguage] !== '') {
            translatedText = i18nUI[langkey][selectedLanguage]
          }
        }
        //  i18nUI[langkey] ? i18nUI[langkey][selectedLanguage] : defaultText

        return (
          <TextElement
            textBoundaries={textBoundaries}
            key={i}
            shapeProps={shape}
            isSelected={shape.id === selectedId}
            translatedText={translatedText}
            onSelect={() => {
              selectShape(shape.id);
              setSelectedIndex(shapes.findIndex(s => s.id === shape.id));
            }}
            onChange={newAttrs => {
              if (!shapeIsValid(newAttrs)) {
                return false;
              }
              const shs = deepClone(shapes);
              // shs[i] = newAttrs;
              shs[i] = {
                ...shape,
                ...newAttrs
              }
              setShapes(shs);
            }}
          />
        );
      }
      if (shape.type === 'circ') {
        return (
          <Circle
            key={i}
            shapeProps={shape}
            isSelected={shape.id === selectedId}
            onSelect={() => {
              selectShape(shape.id);
              setSelectedIndex(shapes.findIndex(s => s.id === shape.id));
            }}
            onChange={newAttrs => {
              if (!shapeIsValid(newAttrs)) {
                return false;
              }
              const shs = deepClone(shapes);
              // shs[i] = newAttrs;
              shs[i] = {
                ...shape,
                ...newAttrs
              }
              setShapes(shs);
            }}
          />
        );
      }
      if (shape.type === 'line') {
        return (
          <TransformableLine
            key={i}
            shapeProps={shape}
            isSelected={shape.id === selectedId}
            onSelect={() => {
              selectShape(shape.id);
              setSelectedIndex(shapes.findIndex(s => s.id === shape.id));
            }}
            onChange={newAttrs => {
              const shs = deepClone(shapes);
              // shs[i] = newAttrs;
              shs[i] = {
                ...shape,
                ...newAttrs
              }
              setShapes(shs);
            }}
          />
        );
      }
      if (shape.type === 'image') {
        return (
          <Img
            key={i}

            imageUrl={shape.content}

            id={shape.id}
            type={shape.type}
            x={shape.x}
            y={shape.y}
            opacity={shape.opacity}
            width={shape.width}
            height={shape.height}
            rotation={shape.rotation}
            locked={shape.locked}

            isSelected={shape.id === selectedId}
            onSelect={() => {
              selectShape(shape.id);
              setSelectedIndex(shapes.findIndex(s => s.id === shape.id));
            }}
            onChange={newAttrs => {
              if (!shapeIsValid(newAttrs)) {
                return false;
              }
              const shs = deepClone(shapes);
              shs[i] = {
                ...shape,
                ...newAttrs
              }
              setShapes(shs);
            }}
          />
        );
      }
      if (shape.type === 'qr') {
        return (
          <Qr
            key={i}
            id={shape.id}
            type={shape.type}
            x={shape.x}
            y={shape.y}
            width={shape.width}
            height={shape.height}
            rotation={shape.rotation}
            locked={shape.locked}

            isSelected={shape.id === selectedId}
            onSelect={() => {
              selectShape(shape.id);
              setSelectedIndex(shapes.findIndex(s => s.id === shape.id));
            }}
            onChange={newAttrs => {
              if (!shapeIsValid(newAttrs)) {
                return false;
              }
              const shs = deepClone(shapes);
              // shs[i] = newAttrs;
              shs[i] = {
                ...shape,
                ...newAttrs
              }
              setShapes(shs);
            }}
          />
        );
      }

    });

    return toRender;


  }

  const onLockBackground = () => {
    changeSettings(!settings.backgroundLocked, "backgroundLocked");
  }

  const onLockLayer = (e, index) => {
    e.stopPropagation();
    const shs = deepClone(shapes);
    shs[index].locked = !shs[index].locked;
    setShapes(shs);
  }

  const onCopyLayer = (e, index) => {
    e.stopPropagation();
    const shs = deepClone(shapes);
    let elementToCopy = deepClone(shs[index]);
    // create new id and lang key
    let oldId = elementToCopy.id;
    let newId = elementToCopy.type + "-" + uuidv1();
    elementToCopy.id = newId;
    elementToCopy.langkey = newId;
    // move the element a bit
    elementToCopy.y = elementToCopy.y + 20;
    elementToCopy.x = elementToCopy.x + 20;
    // if it's a text element, update also translations
    let locali18n = deepClone(i18nUI)
    if (typeof locali18n[oldId] !== 'undefined') {
      locali18n[newId] = deepClone(locali18n[oldId])
    }
    updateI18NUI(locali18n);

    // push after the copied element
    shs.splice(index + 1, 0, elementToCopy);

    setShapes(shs);
    selectLayer(newId, index + 1);
  }

  function onLayerSortEnd(e) {
    const { active, over } = e;
    let oldIndex, newIndex;
    shapes.forEach((element, index) => {
      if (element.id === active.id) {
        oldIndex = index;
      }
      if (element.id === over.id) {
        newIndex = index;
      }
    });

    const shs = deepClone(shapes);
    let elementToMove = shs.splice(oldIndex, 1);
    // console.log(elementToMove);
    shs.splice(newIndex, 0, ...elementToMove);

    selectLayer(elementToMove[0].id, newIndex);
    setShapes(shs);
  }

  const selectLayer = (id, index) => {
    // console.log('selectlayer', id, index);
    selectShape(id);
    setSelectedIndex(index);
  }
  //var widthMultiplier = selectedId === null ? 0.83 : 0.666;
  var widthMultiplier = 0.83;

  // Check if import is in process
  if (loading) {
    if (!uiBlockedState) { setUiBlockedState(true) }
    // TODO set loading spinner?
    // return <div>Loading...</div>;
  }

  // Notice that import is finished and last state was "blocking", process imported file
  if (uiBlockedState && !loading) {
    processImportFiles(filesContent)
    //.then(() => {
    setUiBlockedState(false)
    //   console.log(`processing import`)
    // })
  }




  const templateName = new URLSearchParams(search).get("name");
  if (templateName && settings["templateName"] !== templateName) {
    changeSettings(templateName, "templateName")
    // console.log(templateName)
  }

  const dragUrl = React.useRef();

  const getTabs = () => {
    let tabs = [
      {
        label: 'Text',
        disabled: getTypeById(selectedId) !== 'textelement'
      },
      {
        label: 'Color',
        disabled: false
      },
      {
        label: 'Position',
        disabled: false
      }
    ];
    return tabs;
  }

  const RenderTabs = () => {

    let tabs = getTabs();
    let TabsJSX = tabs.map((item, index) => {
      return <Tab className="tab" key={index} value={index} label={item.label} disabled={item.disabled} />
    });

    return (
      <Tabs
        value={selectedTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleTabChange}
      >
        {TabsJSX}
      </Tabs>
    );

  }

  const getButtonUDF = (udfName, defaultLabel) => {
    if (isEnabledUDF(udfName)) {
      let udfLabel = getLabelUDF(udfName, defaultLabel)
      return (
        <Button className="add-shape-button add-mandatory-text-button" variant="contained" color="primary" onClick={() => addDynamicTextElement({ dynelem: udfName })}>{udfLabel}</Button>
      )
    }
  }
  const isEnabledUDF = (udfName) => {
    // console.log(udfName)
    // console.log(JSON.stringify(settings.display))
    return settings.display.optionalfields.options?.includes(udfName) || settings.display.optionalfields.options?.includes("all")
  }

  const getLabelUDF = (udfName, defaultLabel) => {
    let returnLabel = undefined
    if (settings.display.optionalfields.hasOwnProperty('overrides')) {
      returnLabel = settings.display.optionalfields.overrides[udfName] ?? undefined
    }
    if (returnLabel === undefined) {
      returnLabel = defaultLabel ?? `Special field ${udfName}`
    }
    return returnLabel
    // (${udfName})`;
  }

  const changeDefaultFont = (action, data) => {
    // console.log(data);
    switch (action) {
      case 'family':

        changeSettings(data, 'defaultFontMulti');

        break;
      case 'size':
        changeSettings(data, 'defaultFontSize');
        break;
      case 'bold':
        if (settings.defaultFontStyle) {
          let fontStyleArr = settings.defaultFontStyle.split(' ');

          if (fontStyleArr[0] === 'bold') {
            fontStyleArr[0] = 'normal';
          }
          else if (fontStyleArr[0] === 'normal' || typeof fontStyleArr[0] === 'undefined') {
            fontStyleArr[0] = 'bold';
          }
          let fontStyle = fontStyleArr.join(' ');
          changeSettings(fontStyle, 'defaultFontStyle');
        }
        else {
          changeSettings('bold', 'defaultFontStyle');
        }
        break;
      case 'italic':
        if (settings.defaultFontStyle) {
          let fontStyleArr = settings.defaultFontStyle.split(' ');

          if (fontStyleArr[1] === 'italic') {
            fontStyleArr[1] = 'normal';
          }
          else if (fontStyleArr[1] === 'normal' || typeof fontStyleArr[0] === 'undefined') {
            fontStyleArr[1] = 'italic';
          }
          let fontStyle = fontStyleArr.join(' ');
          changeSettings(fontStyle, 'defaultFontStyle');
        }
        else {
          changeSettings('normal italic', 'defaultFontStyle');
        }
        break;
      default:
        break;
    }
  }

  const applyDefaultFontToAllElements = (action) => {
    const shs = deepClone(shapes);
    // console.log('action', action);
    shs.forEach(element => {
      if (element.type === 'textelement') {
        if (action == 'family') {
          element.fontFamily = settings.defaultFontFamily;
          if (settings.defaultFontUrl && settings.defaultFontUrl.length > 0) {
            element.fontUrl = settings.defaultFontUrl;
          } else {
            delete (element.fontUrl)
          }
          element.fontStyle = settings.defaultFontStyle;
        }
        if (action == 'size') {
          element.fontSize = settings.defaultFontSize;
        }
      }
    });
    setShapes(shs);
  }



  return (

    <div className="App">

      <ThemeProvider theme={theme}>

        {!userLoggedIn && (
          <div className="genericOverlay">Logged out...<br /><Button onClick={() => window.location.reload()} variant="contained" size="large" color="primary">Reload page</Button></div>
        )}
        {backendSettings.type === 'loading' && (
          <div className="genericOverlay">Loading settings...</div>
        )}

        <SettingsModal
          open={settingsModalOpen}
          handleClose={closeSettings}
          settings={settings}
          handleGuideColChange={(e) => changeSettings(e, 'guideColumns')}
          handleGuideRowChange={(e) => changeSettings(e, 'guideRows')}
          handleBackgroundColorChange={(e) => changeSettings(e, 'backgroundColor')}
          handleDefaultFontChange={(action, data) => changeDefaultFont(action, data)}
          handleApplyFontToAllElements={(action) => applyDefaultFontToAllElements(action)}
          backendSettings={backendSettingsRef.current}
        />
        <BrowseTemplatesModal
          url={backendSettings.endpoints?.templatelist}
          open={browseTemplatesModalOpen}
          handleClose={closeBrowseTemplates}
          settings={settings}
          handleChangeTemplate={changeTemplate}
        />
        <BrowseTemplatesModal
          url={backendSettings.endpoints?.exampletemplatelist}
          open={browseExampleTemplatesModalOpen}
          handleClose={closeExampleBrowseTemplates}
          settings={settings}
          handleChangeTemplate={changeTemplate}
        />
        <TopBar
          features={backendSettingsRef.current.features ?? {}}
          guides={guides}
          handleGuides={onToggleGuides}
          textBoundaries={textBoundaries}
          handleTextBoundaries={onToggleTextBoundaries}
          resetZoom={resetZoom}
          onSave={onSave}
          onLoad={onLoad}
          onImport={onImport}
          onExport={onExport}
          onClear={onClear}
          onRender={onRender}
          openSettings={openSettings}
          openBrowsePublicTemplates={openExampleBrowseTemplates}
          openBrowseTemplates={openBrowseTemplates}
          languages={settings.templateLanguages}
          selectedLanguage={selectedLanguage}
          onChangeDisplayLanguage={(lang) => setSelectedLanguage(lang)}
          saveNeeded={unSavedChanges}
        />
        <Container fluid>
          <Row >
            <Col xs={selectedId != null ? 10 : 10} className="stageCol"
              onDrop={(e) => {
                e.preventDefault();
                // register event position
                stageEl.current.setPointersPositions(e);

                // add image
                let imageObj = dragUrl.current;
                imageObj.x = stageEl.current.getPointerPosition().x - stageEl.current.x();
                imageObj.y = stageEl.current.getPointerPosition().y - stageEl.current.y();
                addImage(imageObj);

              }}
              onDragOver={(e) => e.preventDefault()}
            >
              <div className="scaleIndicator"><Button variant="contained" onClick={() => zoomClick(-10)}>-</Button> Zoom: {scaleIndicator}% <Button variant="contained" onClick={() => zoomClick(10)}>+</Button></div>

              {/* A4 size 	3508 x 2480 divided by 4 */}

              <Stage
                width={window.innerWidth * widthMultiplier}
                height={window.innerHeight - 70}
                className="stage"
                id="stageElement"
                ref={stageEl}
                onMouseMove={e => {
                  //deselect when clicking an empty area
                  //console.log(e.target);

                  let type = e.target.attrs.type;
                  const hoverOnObject = type === 'rect' || type === 'circ' || type === 'qr' || type === 'image' || type === 'textelement' || type === 'line';
                  if (hoverOnObject) {
                    stageEl.current.container().style.cursor = 'all-scroll';
                  }
                  else if (type == "transformLeft" || type == "transformRight") {
                    stageEl.current.container().style.cursor = 'ew-resize';
                  }

                  else {
                    stageEl.current.container().style.cursor = 'default';
                  }

                  /*   if (e.target.attrs.name &&  e.target.attrs.name.includes("rotater")) {
                      console.log('hover on rotater');

                      document.querySelectorAll('.konvajs-content')[0].classList.add("hasRotater");
                    }
                    else {
                      document.querySelectorAll('.konvajs-content')[0].classList.remove("hasRotater");
                    } */
                }}
                // center stage
                x={window.innerHeight * widthMultiplier / 2}

                draggable={!settings.backgroundLocked}
                onMouseDown={e => {
                  //deselect when clicking an empty area

                  const clickedOnEmpty = e.target === e.target.getStage();
                  if (clickedOnEmpty) {
                    selectShape(null);
                    setSelectedIndex(null);
                  }
                }}

              >

                <RenderBoundaries />


                <Layer ref={layerEl}>
                  <Rect
                    className="layerBackground"
                    fill={settings.backgroundColor}
                    x={0}
                    y={0}
                    width={UI.paperSizeA4[0]}
                    height={UI.paperSizeA4[1]}>
                  </Rect>
                  <RenderShapes />

                </Layer>

                <RenderGuides />

              </Stage>

            </Col>

            {selectedId != null && (<>
              <div /* xs={2} */ className="shapeattributes-sidebar">

                <div className="shapeAttributes">

                  <h5>Shape attributes</h5>



                  <RenderTabs />



                  {getTypeById(selectedId) === 'textelement' && (
                    <div className="tabpanel" role="tabpanel" hidden={selectedTab !== 0}>
                      {selectedId != null && selectedIndex != null && typeof selectedIndex != 'undefined' && getTypeById(selectedId) === 'textelement' && typeof shapes[selectedIndex] != 'undefined' && typeof shapes[selectedIndex].fontFamily != 'undefined' && (<>
                        <Row>

                          {!shapes[selectedIndex].dynamicText && (
                            <Col xs={12}>
                              <label className="mt-2 attribute-label">Text</label>
                              {settings.templateLanguages.map((lang, index) => {
                                const defaultValue = ''
                                const texthint = `untranslated: ${lang}`
                                let textValue = defaultValue
                                // shapes lang key, @TODO use langkey and not shape id
                                if (i18nUI[shapes[selectedIndex].id]) {
                                  if (i18nUI[shapes[selectedIndex].id][lang] && i18nUI[shapes[selectedIndex].id][lang] !== '') {
                                    textValue = i18nUI[shapes[selectedIndex].id][lang]
                                  }
                                }
                                return (
                                  <div key={index}>
                                    <label className="attached">{isoLangs[lang].name || lang}</label>
                                    <textarea className="mb-3 js-disable-keybinds" placeholder={texthint} value={textValue} onChange={(text) => changeTranslationForElement(text, lang, shapes[selectedIndex].id)}></textarea>
                                  </div >
                                )
                              })}
                            </Col>
                          )}
                          <Col xs={12}>

                            <label className="attribute-label">Font</label>

                            <FontSelector
                              backendSettings={backendSettingsRef.current}
                              fontWeight={shapes[selectedIndex].fontStyle && shapes[selectedIndex].fontStyle.includes('bold') ? 'bold' : ''}
                              fontStyle={shapes[selectedIndex].fontStyle && shapes[selectedIndex].fontStyle.includes('italic') ? 'italic' : ''}
                              activeFontFamily={shapes[selectedIndex].fontFamily}
                              fontType={shapes[selectedIndex].fontStyle && shapes[selectedIndex].fontStyle.includes('single') ? 'single' : ''}
                              onChangeFont={(nextFont) => changeTextFont(nextFont)}
                              toggleBold={toggleBold}
                              toggleItalic={toggleItalic}
                            />
                            <FormControlLabel
                              value="forceUppercase"
                              onChange={handleTextUppercase}
                              control={<Checkbox />}
                              checked={shapes[selectedIndex].textTransform === "upperCase"}

                              label="Force text uppercase"
                            />
                            {/* <FormControlLabel
                              value="forceLowercase"
                              onChange={handleTextLowercase}
                              control={<Checkbox />}
                              checked={shapes[selectedIndex].textTransform === "lowerCase"}
                              label="Force text lowercase"

                            />*/}
                            {/* <FormControlLabel
                              value="forceTrim"
                              onChange={handleTextTrim}
                              control={<Checkbox />}
                              checked={shapes[selectedIndex].textTransform === "trim"}
                              label="Force trim"
                            /> */}


                            <label className="attribute-label">Font size</label>

                            <input type="number" min="1" onChange={(value) => changeFontSize(value)} value={shapes[selectedIndex].fontSize}></input>


                          </Col>
                          <Col xs={6}>
                            <label className="attribute-label">Align text</label>
                            <ButtonGroup size="small" aria-label="small outlined button group">
                              <Button onClick={() => toggleAlign('left')} color={shapes[selectedIndex].align === 'left' ? 'primary' : 'neutral'}><FormatAlignLeft /></Button>
                              <Button onClick={() => toggleAlign('center')} color={shapes[selectedIndex].align === 'center' ? 'primary' : 'neutral'}><FormatAlignCenter /></Button>
                              <Button onClick={() => toggleAlign('right')} color={shapes[selectedIndex].align === 'right' ? 'primary' : 'neutral'}><FormatAlignRight /></Button>
                            </ButtonGroup>

                          </Col>
                          <Col xs={6}>
                            <label className="attribute-label">Align vertically</label>
                            <ButtonGroup size="small" aria-label="small outlined button group">
                              <Button onClick={() => toggleVerticalAlign('top')} color={shapes[selectedIndex].verticalAlign === 'top' ? 'primary' : 'neutral'}><VerticalAlignTop /></Button>
                              <Button onClick={() => toggleVerticalAlign('middle')} color={shapes[selectedIndex].verticalAlign === 'middle' ? 'primary' : 'neutral'}><VerticalAlignCenter /></Button>
                              <Button onClick={() => toggleVerticalAlign('bottom')} color={shapes[selectedIndex].verticalAlign === 'bottom' ? 'primary' : 'neutral'}><VerticalAlignBottom /></Button>
                            </ButtonGroup>
                          </Col>
                          <Col xs={12}>
                            <FormControlLabel
                              value="multiline"
                              onChange={handleTextMultiline}
                              control={<Checkbox />}
                              checked={shapes[selectedIndex].multiline === undefined || shapes[selectedIndex].multiline === true}

                              label="Allow multiple lines"
                            />
                          </Col>
                          {!shapes[selectedIndex].dynamicText && (
                          <Col xs={12}>
                            <FormControlLabel
                              value="dependsOnDynamicTextElement"
                              onChange={handleHideIfNoValue}
                              control={<Checkbox />}
                              checked={shapes[selectedIndex].dependsOnDynamicTextElement?.name === "valueText"}

                              label="Hide if value is not displayed in the gift voucher"
                            />
                          </Col>
                          )}

                        </Row>

                      </>)}

                    </div>
                  )}
                  <div className="tabpanel" role="tabpanel" hidden={selectedTab !== 1}>
                    {selectedId != null && selectedIndex != null && typeof selectedIndex != 'undefined' && typeof shapes[selectedIndex] != 'undefined' && (<>
                      {getTypeById(selectedId) !== 'line' && (
                        <>
                          <label className="attribute-label">Color</label>
                          <SketchPicker color={(selectedIndex && shapes[selectedIndex].fill) || selectedColor} disableAlpha={true} onChangeComplete={handleColorChange} presetColors={pickerColorList} />
                        </>
                      )}
                      <label className="attribute-label">Opacity</label>
                      <Slider value={shapes[selectedIndex].opacity > -1 ? shapes[selectedIndex].opacity * 100 : 100} min={0} max={100} onChange={handleOpacityChange} aria-labelledby="continuous-slider" />

                      {(getTypeById(selectedId) === 'rect' || getTypeById(selectedId) === 'circ' || getTypeById(selectedId) === 'textelement' || getTypeById(selectedId) === 'line') && (
                        <>
                          <label className="attribute-label">Border color</label>
                          <SketchPicker color={(selectedIndex && shapes[selectedIndex].stroke) || selectedStrokeColor} disableAlpha={true} onChangeComplete={handleStrokeColorChange} presetColors={pickerColorList} />
                          <label className="attribute-label">Border width</label>
                          <input type="number" min="0" onChange={(value) => handleStrokeWidthChange(value)} value={shapes[selectedIndex].strokeWidth}></input>
                        </>
                      )}


                    </>)}
                  </div>
                  <div className="tabpanel" role="tabpanel" hidden={selectedTab !== 2}>
                    {selectedId != null && selectedIndex != null && typeof selectedIndex != 'undefined' && typeof shapes[selectedIndex] != 'undefined' && (<>
                      <label className="attribute-label">Position</label>
                      <Button className="button-full-width mb-3" variant="contained" color="primary" onClick={() => changeZindex('up')}>
                        <ArrowUpward /> Bring to front
                      </Button>
                      <Button className="button-full-width mb-3" variant="contained" color="primary" onClick={() => changeZindex('down')}>
                        <ArrowDownward /> Bring to back
                      </Button>


                      <Row>
                        <Col xs={6}>
                          <label className="mt-2 attribute-label">X: </label> <input type="number" className="js-disable-keybinds" onChange={(value) => changeObjectX(value)} value={shapes[selectedIndex].x}></input>
                        </Col>
                        <Col xs={6}>
                          <label className="mt-2 attribute-label">Y: </label> <input type="number" className="js-disable-keybinds" onChange={(value) => changeObjectY(value)} value={shapes[selectedIndex].y}></input>
                        </Col>

                      </Row>



                      <Row>
                        <Col xs={6}>
                          <label className="attribute-label">Width: </label>
                          <input type="number" className="js-disable-keybinds" onChange={(value) => changeObjectWidth(value)} value={shapes[selectedIndex].width}></input>
                        </Col>
                        <Col xs={6}>
                          <label className="attribute-label">Height: </label>
                          <input type="number" className="js-disable-keybinds" onChange={(value) => changeObjectHeight(value)} value={shapes[selectedIndex].height}></input>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6}>
                          <label className="mt-2 attribute-label">Rotation: </label> <input type="number" className="js-disable-keybinds" onChange={(value) => changeObjectRotation(value)} value={shapes[selectedIndex].rotation}></input>
                        </Col>
                      </Row>




                    </>)}
                  </div>

                </div>

              </div>
            </>
            )}
            <Col xs="2" className="designer-sidebar">

              <div className="addShapes">

                <Accordion expanded={accordionExpanded === 'panel1'} onChange={handleAccordionChange('panel1')} square>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <h5>Insert object</h5>

                  </AccordionSummary>
                  <AccordionDetails>
                    <p className="accordion-instructions">Click on a button to add and object to your gift card.</p>
                    <Button className="add-shape-button" variant="contained" color="secondary" onClick={addRectangle} startIcon={<CropDin />}>
                      Rectangle
                    </Button>
                    <Button className="add-shape-button" variant="contained" color="secondary" onClick={addCircle} startIcon={<RadioButtonUnchecked />}>
                      Circle
                    </Button>
                    <Button className="add-shape-button" variant="contained" color="secondary" onClick={addLine} startIcon={<HorizontalRule />}>
                      Line
                    </Button>
                    {/* <Button variant="secondary" onClick={drawLine}>
                    Line
                  </Button>
                  <Button variant="secondary" onClick={eraseLine}>
                    Erase
                  </Button> */}
                    <Button className="add-shape-button" variant="contained" color="secondary" onClick={() => addTextElement()} startIcon={<TextFields />}>
                      Text
                    </Button>
                    {/* <Button className="add-shape-button" variant="contained" color="secondary" onClick={drawImage} startIcon={<CropOriginal />}>
                      Image
                    </Button> */}
                    <Button className="add-shape-button" variant="contained" color="secondary" onClick={addQRcode} disabled={hasQrCode}> <i className="fal fa-qrcode qr-icon"></i>
                      QR-code
                    </Button>
                    {/* <Button variant="secondary" onClick={undo}>
                    Undo
                  </Button> */}


                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={accordionExpanded === 'panel2'} onChange={handleAccordionChange('panel2')} square>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                  >
                    <h5>Images</h5>

                  </AccordionSummary>
                  <AccordionDetails>
                    <p className="accordion-instructions">Click the 'add image' button to upload images to your gift cards. The added images will first appear here. you can then click on them to add them to your design. (Max size per image is {imageSizeMiB} megabyte and {imageMaxWidth}x{imageMaxHeight} pixels)</p>

                    <ImageBank
                      onImageToCanvas={addImage}
                      updateImages={updateImages}
                      imagesObj={imagesRef.current}
                      dragUrl={dragUrl}

                    />
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={accordionExpanded === 'panel3'} onChange={handleAccordionChange('panel3')} square>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                  >
                    <h5>Insert mandatory fields</h5>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p className="accordion-instructions">Insert a mandatory text field here. These text fields are automatically filled according to the purchased gift card's details. You can specify the position and style of how this information is displayed.</p>

                    <Button className="add-shape-button add-mandatory-text-button" variant="contained" color="primary" onClick={() => addDynamicTextElement({ dynelem: 'voucherTitle' })} disabled={mandatoryFields.voucherTitle}>{mandatoryFields.voucherTitle && <CheckCircleOutline />} Gift card title</Button>

                    <Button className="add-shape-button add-mandatory-text-button" variant="contained" color="primary" onClick={() => addDynamicTextElement({ dynelem: 'voucherText' })} disabled={mandatoryFields.voucherText}>{mandatoryFields.voucherText && <CheckCircleOutline />} Gift card description</Button>

                    <Button className="add-shape-button add-mandatory-text-button" variant="contained" color="primary" onClick={() => addDynamicTextElement({ dynelem: 'validityStartDate' })} disabled={mandatoryFields.validityStartDate}>{mandatoryFields.validityStartDate && <CheckCircleOutline />} Validity start</Button>

                    <Button className="add-shape-button add-mandatory-text-button" variant="contained" color="primary" onClick={() => addDynamicTextElement({ dynelem: 'expirationDate' })} disabled={mandatoryFields.expirationDate}>{mandatoryFields.expirationDate && <CheckCircleOutline />} Validity end</Button>

                    <Button className="add-shape-button add-mandatory-text-button" variant="contained" color="primary" onClick={() => addDynamicTextElement({ dynelem: 'refNumber' })} disabled={mandatoryFields.refNumber}>{mandatoryFields.refNumber && <CheckCircleOutline />} Voucher number</Button>

                    <Button className="add-shape-button add-mandatory-text-button" variant="contained" color="primary" onClick={() => addDynamicTextElement({ dynelem: 'customerMessage' })} disabled={mandatoryFields.customerMessage}>{mandatoryFields.customerMessage && <CheckCircleOutline />} Message from sender</Button>

                    <Button className="add-shape-button add-mandatory-text-button" variant="contained" color="primary" onClick={() => addDynamicTextElement({ dynelem: 'valueText' })} disabled={mandatoryFields.valueText}>{mandatoryFields.valueText && <CheckCircleOutline />} Value</Button>

                  </AccordionDetails>
                </Accordion>

                {settings.display.optionalfields.enabled && (
                  <Accordion expanded={accordionExpanded === 'panel5'} onChange={handleAccordionChange('panel5')} square>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel5bh-content"
                      id="panel5bh-header"
                    >
                      <h5>Insert optional fields</h5>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p className="accordion-instructions">Insert a optional text field here. These text fields are automatically filled according to the purchased gift card's details. You can specify the position and style of how this information is displayed.</p>
                      {getButtonUDF('voucherRestrictions', 'Voucher restrictions')}

                      {getButtonUDF('voucherTermsAndConditions', 'Terms and conditions')}

                      {getButtonUDF('udf01', 'Booking code (udf01)')}
                      {getButtonUDF('udf02', 'Special field (udf02)')}
                      {getButtonUDF('udf03', 'Special field (udf03)')}
                      {getButtonUDF('udf04', 'Special field (udf04)')}
                      {getButtonUDF('udf05', 'Special field (udf05)')}

                    </AccordionDetails>
                  </Accordion>
                )}


                <Accordion expanded={accordionExpanded === 'panel4'} onChange={handleAccordionChange('panel4')} square>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                  >
                    <h5>Object list</h5>


                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="layersList">
                      <p className="accordion-instructions">Drag an object to change the order of individual objects. You can also copy an object here. You can also lock the object so it cannot be selected or moved.</p>

                      <li className="layerRow no-handle" >
                        <div>
                          <span className="layerTag">Background</span>
                        </div>
                        <Button className="float-right" title={settings.backgroundLocked ? "Unlock background" : "Lock background"} onClick={(e) => onLockBackground()}>{settings.backgroundLocked ? <LockRounded /> : <LockOpenRounded />}</Button>
                      </li>

                      <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragEnd={onLayerSortEnd}
                        modifiers={[restrictToVerticalAxis, restrictToParentElement]}


                      >
                        <SortableContext
                          items={shapes}
                          strategy={verticalListSortingStrategy}


                        >

                          {shapes.map((item, anoterIndex) => {

                            let classes = "layerRow";
                            if (selectedIndex === anoterIndex) {
                              classes = "layerRow isActive";
                            }
                            let translatedText = ''
                            if (item.type === 'textelement') {
                              const defaultText = item.dynamicText ?? `untranslated: ${selectedLanguage}`
                              let langkey = item.langkey ?? item.id
                              translatedText = defaultText
                              if (i18nUI[langkey]) {
                                if (i18nUI[langkey][selectedLanguage] && i18nUI[langkey][selectedLanguage] !== '') {
                                  translatedText = i18nUI[langkey][selectedLanguage]
                                }
                              }
                            }
                            return <SortableLayer
                              key={item.id}
                              id={item.id}
                              classes={classes}
                              onSelectLayer={selectLayer}
                              type={item.type}
                              text={translatedText}
                              anoterIndex={anoterIndex}
                              locked={item.locked}
                              isDynamic={item.dynamicText && item.dynamicText.length > 0 ? true : false}
                              onLockLayer={(a, b) => onLockLayer(a, b)}
                              onCopyLayer={(a, b) => onCopyLayer(a, b)}
                            />;

                          })}

                        </SortableContext>
                      </DndContext>

                    </div>
                  </AccordionDetails>
                </Accordion>

              </div>
            </Col>
          </Row>


        </Container>
        {/* <input
          style={{ display: "none" }}
          type="file"
          ref={fileUploadEl}
          onChange={fileChange}
        /> */}


      </ThemeProvider>
      <canvas id='fonthelper' style={{ display: "none" }} ></canvas>
    </div >
  );


}



export default App;

