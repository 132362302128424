

import { v1 as uuidv1 } from 'uuid';


// const getRandomInt = max => {
//     return Math.floor(Math.random() * Math.floor(max));
// };


const getImageProps = async (source) => {
    let placeholder = new Image();
    var w, h;

    placeholder.src = await source;

    w = parseInt(placeholder.width);
    h = parseInt(placeholder.height);

    if (w === 0) {
        w = 600;
    }
    if (h === 0) {
        h = 400;
    }

    placeholder = null;

    return { w: w, h: h };
}

export const rectangle = (opts, settings) => {
    const id = 'rect-' + uuidv1();
    const rect = {
        type: 'rect',
        x: -100,
        y: 100,
        width: 100,
        height: 100,
        fill: "#000000",
        stroke: "#000000",
        strokeWidth: 0,
        locked: false,
    };
    const newShape = {
        ...rect,
        ...opts,
        id: id
    }

    return newShape

};


const qrcode = (opts, settings) => {
    const qr = {
        id: 'qrcode-' + uuidv1(),
        type: 'qr',
        x: opts.x,
        y: opts.y,
        width: 60,
        height: 60,
        locked: false,
    }
    const newShape = {
        ...qr,
        ...opts,
    }

    return newShape

};

export const circle = (opts, settings) => {
    const circ = {
        type: 'circ',
        x: opts.x,
        y: opts.y,
        width: 100,
        height: 100,
        // radius: 70,
        fill: "#222222",
        stroke: "#000000",
        strokeWidth: 0,
        id: 'circ-' + uuidv1(),
        locked: false,
    };

    const newShape = {
        ...circ,
        ...opts,
    }

    return newShape

};

export const line = (opts, settings) => {
    const line = {
        type: 'line',
        x: opts.x,
        y: opts.y,
        width: opts.width,
        height: opts.height,
        rotation: 0,
        points: [opts.x, opts.y, opts.x+opts.width, opts.y+opts.height],
        stroke: "#000000",
        strokeWidth: 1,
        id: 'line-' + uuidv1(),
        locked: false,
    };

    const newShape = {
        ...line,
        ...opts,
    }

    return newShape

};

export const image = async (opts, settings) => {
    const imageObject = opts.content || ''
    const id = 'image-' + uuidv1();
    let name = opts.name
    let storageId = opts.storageId || name

    // console.log(imageObject)



    const imageUrl = imageObject
    var imgProps = await getImageProps(imageUrl);

    const image = {
        name: name,
        storageId: storageId,
        content: imageUrl,
        id: id,
        type: 'image',
        x: opts.x,
        y: opts.y,
        width: imgProps.w,
        height: imgProps.h,
        locked: false,
    }
    // console.log(image)

    return image

}


export const dynamicTextElement = (opts, settings) => {
    let id = 'textelement-' + uuidv1();
    let text = opts.text ?? 'text';
    let width = opts.width || 300;
    let height = opts.height || 100;
    let fontsize = opts.fontsize || settings.defaultFontSize;
    let langkey = opts.langkey || id;

    let textelement = {
        type: 'textelement',
        textTransform: 'none',
        langkey: langkey,
        multiline: true,
        x: opts.x,
        y: opts.y,
        width: width,
        height: height,
        text: text,
        fontSize: fontsize,
        align: 'center',
        verticalAlign: 'middle',
        fontFamily: settings.defaultFontFamily,
        fontStyle: settings.defaultFontStyle,
        id: id,
        locked: false,
        rotation: 0,
    };

    // console.log(opts)

    if (opts['dynelem']) {
        let dynamicTextType = opts['dynelem']
        if (dynamicTextType != null) {
            textelement.id = 'textelement-' + dynamicTextType + '-' + uuidv1();
            textelement.text = dynamicTextType;
            textelement.dynamicText = dynamicTextType;
        }

    }
    // console.log(textelement)

    return textelement


};
export const textElement = dynamicTextElement


export default {
    rectangle,
    circle,
    line,
    image,
    qrcode,
    textElement,
    dynamicTextElement
}
