import React from "react";
import { Image, Transformer } from "react-konva";
import useImage from "use-image";

const Qr = ({ id,type,x,y,width, height, rotation, isSelected, onSelect, onChange, locked }) => {
	const shapeRef = React.useRef();
	const trRef = React.useRef();
	const [image] = useImage('qr.png');
	const canTransform = isSelected && !locked;

	React.useEffect(() => {
		if (canTransform) {
			//attaching transformer manually
			trRef.current.setNodes([shapeRef.current]);
			trRef.current.getLayer().batchDraw();
		}
	}, [canTransform]);

	return(
		<React.Fragment>
			<Image 
				onClick={!locked && onSelect}
				image={image}
				ref={shapeRef}
				id={id}
				x={x}
				y={y}
				width={width}
				height={height}
				rotation={rotation}
				type='qr'
				draggable={canTransform}
				onDragEnd={e => {
					onChange({
						id:id,
						type:type,
						rotation: rotation,
						x: e.target.x(),
						y: e.target.y(),
						width: width,
						height:height,
					});
				}}
				onTransformEnd={e => {
					const node = shapeRef.current;
					const scaleX = node.scaleX();
					const scaleY = node.scaleY();
					onChange({
						id:id,
						rotation: node.rotation(),
						type:type,
						x: node.x(),
						y: node.y(),
						width: node.width() * scaleX,
						height: node.height() * scaleY,
					});
				}}
			/>
			{canTransform && <Transformer ref={trRef} rotateEnabled={false} resizeEnabled={false} />}
		</React.Fragment>
	);
};

export default Qr;
