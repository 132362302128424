import React, { useState, useEffect } from "react";
import { Button, ButtonGroup, TextField } from '@mui/material';
import { FormatBold, FormatItalic } from '@mui/icons-material';
import { Row, Col } from 'reactstrap';
import Autocomplete from '@mui/material/Autocomplete';

import { fetchFontList } from '../utils/remoteOperations';

const FontSelector = ({ backendSettings, onChangeFont, toggleBold, activeFontFamily, toggleItalic, fontWeight, fontStyle, fontType }) => {


  /*   useEffect(() => {
      // run on start
    }, []); */


  const [availableFonts, updateFonts] = useState([]);



  useEffect(() => {
    //console.log('active', activeFontFamily);
    GetFontList();
  }, []);

  const GetFontList = async () => {

    let customList = {}
    let mainList = await fetchFontList(backendSettings.endpoints.fontlist)
    try {


      //console.log(backendSettings.endpoints)
      if (backendSettings.endpoints.hasOwnProperty("fontlistCustom")) {
        // console.log("In custom")
        customList = await fetchFontList(backendSettings.endpoints.fontlistCustom)
        // console.log(customList)
        // console.log("In get")
      }

    } catch (e) {
      console.error("error fetchin customlist")
      // console.error(e)
      // skip error
    }

    let fonts = {
      global: [
        ...mainList.global ?? [],
        ...customList.global ?? []
      ],
      custom: [
        ...mainList.custom ?? [],
        ...customList.custom ?? []

      ]
    }
    // console.log("Await fonts")
    ProcessFontList(fonts);

  }

  const ProcessFontList = (availFonts) => {

    // flatten return list
    let fontList = [...availFonts.global ?? [], ...availFonts.custom ?? []]

    // sort by priority and name
    // default priority is 50
    fontList.sort((a, b) => {
      const ap = a.priority ?? 50
      const bp = b.priority ?? 50
      if (ap === bp) {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      } else {
        return ap < bp ? 1 : -1
      }
    })
    //console.log(fontList);
    updateFonts(fontList)
  }

  const fontOptions = () => {
    // console.log('active font', activeFontFamily);
    let options = [];
    let fonts = availableFonts
    let dupName = {}

    fonts.forEach((element, index) => {
      if (activeFontFamily === element.name) {
        element.active = true;
      }
      let fontOpt = { 'label': element.name }
      if (element.hasOwnProperty("url") && element.url !== null) {
        fontOpt["data-fonturl"] = element.url
        fontOpt["data-type"] = "single"
      }
      //options.push(<option key={index} style={{ fontFamily: element.name }}>{element.name}</option>);
      if (!dupName.hasOwnProperty(element.name)) {
        dupName[element.name] = true
        options.push(fontOpt)
      }
    });

    return options;
  }



  return (

    <div className="fontselector-container">

      {availableFonts &&
        <Autocomplete
          value={activeFontFamily}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          disablePortal
          disableClearable
          onChange={(event, newValue) => {
            onChangeFont(newValue);
          }}
          id="font-selector-autocomplete"
          options={fontOptions()}
          renderInput={(params) => <TextField {...params} variant="standard" />}
        />
      }
      {/*  <select onChange={onChangeFont} value={activeFontFamily}>
        <FontOptions />
      </select> */}
      {fontType !== 'single' &&
        <Row>
          <Col xs={6}>
            <ButtonGroup size="small" className="font-style-group" aria-label="small outlined button group">
              <Button onClick={toggleBold} color={fontWeight == 'bold' ? 'primary' : 'neutral'}><FormatBold /></Button>
              <Button onClick={toggleItalic} color={fontStyle == 'italic' ? 'primary' : 'neutral'}><FormatItalic /></Button>
            </ButtonGroup>
          </Col>
        </Row>
      }


    </div>

  );
};


export default FontSelector;


