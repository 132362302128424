import React, { useState } from "react";
import { Row, Col } from 'reactstrap';

import { useFilePicker } from "use-file-picker";
import { UI } from "../constants"


import { Button } from '@mui/material';
import HighlightOff from '@mui/icons-material/HighlightOff';

// const getBase64 = (file) => {
//   console.log(file)
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.onload = () => resolve(reader.result);
//     reader.onerror = error => reject(error);
//     reader.readAsDataURL(file);
//   });
// }

const imageSizeMiB = UI.imageSizeMiB || 0.8
const imageMaxHeight = UI.imageMaxHeight || 2500
const imageMaxWidth = UI.imageMaxWidth || 2500

const ImageBank = ({ onImageToCanvas, imagesObj, updateImages, dragUrl }) => {

  const { openFilePicker, filesContent, loading, errors, clear} = useFilePicker({
 // const [openFileSelector, { filesContent, loading, errors, plainFiles, clear }] = useFilePicker({
    multiple: false,
    accept: UI.imagesAccept,
    readAs: 'DataURL',
    maxFileSize: imageSizeMiB,
     imageSizeRestrictions: {
       maxHeight: imageMaxHeight, // in pixels
       maxWidth: imageMaxWidth,
       minHeight: 1,
       minWidth: 1,
     },
     onFilesSuccessfullySelected: ({ plainFiles, filesContent }) => {
      // this callback is called when there were no validation errors
      processImages(filesContent)
    },
  });

  const [uploading, setUploading] = useState(false);

  // @TODO add spinner on load
  const onImport = (e) => {
    openFilePicker();
  }

  const processImages = (files) => {
    let newImage = { ...imagesObj };
    let acceptRegex = /.+/
    if (UI.imagesAccept) {
      acceptRegex = new RegExp('.+(' + UI.imagesAccept.split(',').join('|') + ')', 'i')
    }
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      let isMatch = acceptRegex.test(file.name)
      if (isMatch) {
        newImage[file.name] = { id: file.name, name: file.name, content: file.content }
      } else {
        let message = `Wrong file type: ${file.name}`
        errorUpload(message);
      }
    }
    clear();
    // setUploading(false)
    updateImages(newImage);
  }

  const errorUpload = reason => {
    let message = "Image upload failed:\n" + reason
    // DOUBLE alert in development. (render phase is done twice with <React.StrictMode> tags in index.js)
    window.alert(message)

  }

  const removeImage = id => {
    if (window.confirm('Are you sure you want to remove this image?')) {
      let imgs = { ...imagesObj }
      // console.log(`DELETE ${id}`)
      delete imgs[id]
      // let imgs = images.filter(image => image.public_id !== id);
      updateImages(imgs);
    }
  }


  const Images = () => {
    var imgs = [];
    let cnt = 0;
    for (const key in imagesObj) {
      cnt++;
      // for (let i=0; i<images.length; i++) {
      const image = imagesObj[key];
      // console.log(`${key}`)
      imgs.push(
        <div key={key} className="image-content">
          <div onClick={() => removeImage(key)}
            className='image-delete'
          >
            <HighlightOff />
          </div>
          <div key={key} className='imageThumb' onClick={() => onImageToCanvas(image)} onDragStart={(e) => {
            dragUrl.current = image;
          }}>
            <img src={image.content} alt={image.name} />
          </div>
        </div>
      )
    }
    return imgs;
  }

  // Check if import is in process

  //This loading check does not do anything in the new version. If needed, need to refactor.

  if (loading) {
    if (!uploading) {
      //setTimeout(() => setUploading(true),0);
    }
    // console.log("loading");
    // TODO set loading spinner?
    // return <div>Loading...</div>;
  }

  // Notice that import is finished and last state was "blocking", process imported file
  if (uploading && !loading) {
  // console.log("uploading true, loading false ");
    processImages(filesContent)
    //setTimeout(() => setUploading(false) ,0);
  }

  if (errors.length) {
    // console.log("errors");
    // console.log(errors);
    let message = ""
    if (errors[0].fileSizeTooSmall) {
      message += 'File size is too small!\n'
    }
    if (errors[0].fileSizeToolarge) {
      message += `File size is too large! (max: ${imageSizeMiB} MiB)\n`
    }
    if (errors[0].imageHeightTooBig || errors[0].imageWidthTooBig ) {
      message += `Image resolution is too large! (max: ${imageMaxWidth} x  ${imageMaxHeight} pixels)\n`
    }
    if (errors[0].readerError) {
      message += 'Problem occured while reading file!\n'
    }
    if (errors[0].maxLimitExceeded) {
      message += 'Too many files!\n'
    }
    if (errors[0].minLimitNotReached) {
      message += 'Not enought files!\n'
    }
    clear()
    errorUpload(message);
  }

  const content = () => {

    switch (true) {

      case loading:
        return <p>--loading--</p>

      // case Object.keys(imagesObj).length > 0:
      //   return <>
      //     <div className='button'>
      //       {/* <label htmlFor='single'>
      //         Upload images
      //       </label> */}
      //       <Button variant="contained" color="secondary" onClick={onImport}>Add Image</Button>

      //     </div>
      //     <Images images={imagesObj} removeImage={() => removeImage()} />
      //   </>
      default:
        return <>
          <div className='button mb-3 pl-0 col-12'>

            <Button variant="contained" color="secondary" onClick={onImport}>Add Image</Button>

          </div>
          {Object.keys(imagesObj).length > 0 && <div className="mb-3 your-images-header">Your images</div>}
          <Images images={imagesObj} removeImage={() => removeImage()} />
        </>

    }
  }


  return (



    <div className="imagebank-container">
      <Row >
        <Col xs={12} >


          {/* {content()} */}


          <div className='button mb-3 pl-0 col-12'>

            <Button variant="contained" color="secondary" onClick={onImport}>Add Image</Button>

          </div>
          {Object.keys(imagesObj).length > 0 && <div className="mb-3 your-images-header">Your images</div>}
          <Images images={imagesObj} removeImage={() => removeImage()} />


        </Col>

      </Row>
    </div>

  );
};


export default ImageBank;

