import React from "react";
import { Row, Col, Navbar } from 'reactstrap';
import { Switch, Button, ButtonGroup, FormControl, InputLabel, Select, Menu, MenuItem } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Settings, FolderOpen, ArrowDropDown, Save, FileDownload, InsertDriveFileOutlined, DriveFileMoveOutlined, ClearAll, NotificationsOutlined } from '@mui/icons-material';
import isoLangs from '../utils/isoLangs';



const TopBar = ({ features, guides, handleGuides, openBrowsePublicTemplates, textBoundaries, handleTextBoundaries, resetZoom, onSave, onLoad,openBrowseTemplates, onExport, onClear, onImport, onRender, openSettings, languages, selectedLanguage, onChangeDisplayLanguage,saveNeeded }) => {

  const [menuEl, setMenuEl] = React.useState(null);
  const menuOpen = Boolean(menuEl);
  const handleMenuClick = (event) => {
    setMenuEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuEl(null);
  };

  const changeGiftcardDisplayLanguage = (e) => {
    // console.log(e.target.value);
    onChangeDisplayLanguage(e.target.value);
  }

  const unSavedChanges = features.save &&  saveNeeded()
  return (

    <Navbar color="faded" light>
      <Row className="nav-row">
        <Col xs={12} lg={10} className="nav-left">
          <div className="applogo"><span className="logo1">Gift card</span><span className="logo2">Designer</span></div>

          {unSavedChanges && (
              <Button variant="contained" className="quickSaveButton" onClick={() => onSave()}>
                <Save />
              </Button>
          ) }
          <Button
            variant="contained" color="primary"
            id="basic-button"
            aria-controls="basic-menu"
            aria-haspopup="true"
            aria-expanded={menuOpen ? 'true' : undefined}
            onClick={handleMenuClick}
            endIcon={<ArrowDropDown />}
            >
            MENU
          </Button>

          <Menu
            className="fileMenu"
            id="basic-menu"
            anchorEl={menuEl}
            open={menuOpen}
            onClose={handleMenuClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {features.load && (
              <MenuItem onClick={() => {onLoad(); handleMenuClose();}}>
                <FileDownload />
                ReLoad gift card template
              </MenuItem>

            )}
            {features.save && (
              <MenuItem onClick={() => {onSave(); handleMenuClose();}}>
                <Save />
                Save gift card template
                {  unSavedChanges && (
                <NotificationsOutlined />
                ) }
              </MenuItem>

            )}
            <MenuItem  onClick={() => {onImport(); handleMenuClose();}}>
              <InsertDriveFileOutlined />
              Import template from file
            </MenuItem>
            <MenuItem  onClick={() => {onExport(); handleMenuClose();}}>
              <DriveFileMoveOutlined/>
              Export template to file
            </MenuItem>
            <MenuItem  onClick={() => {onClear(); handleMenuClose();}}>
              <ClearAll />
              Clear template
            </MenuItem>
            {features.templatebrowser && (
                <MenuItem  onClick={() => {openBrowseTemplates(); handleMenuClose();}}>
                  <FolderOpen />
                  Switch Template
                </MenuItem>
            )}

            {features.publictemplatebrowser && (
                <MenuItem  onClick={() => {openBrowsePublicTemplates(); handleMenuClose();}}>
                  <FolderOpen /> Premade templates
                </MenuItem>
            )}

            <MenuItem onClick={() => {openSettings(); handleMenuClose();}}>
              <Settings /> Settings
            </MenuItem>

            {/* Mobile */}
            <MenuItem className="d-block d-lg-none" onClick={() => {resetZoom(); handleMenuClose();}}>
              Reset zoom
            </MenuItem>

            <MenuItem className="d-block d-lg-none" onClick={() => {onRender(); handleMenuClose();}}>Render to pdf</MenuItem>
          </Menu>


          <FormControlLabel
            control={
              <Switch
                checked={guides}
                onChange={handleGuides}
                name="Toggle guides"
                color="primary"
              />
            }
            label="Toggle guides"
          />


          <FormControlLabel
            control={
              <Switch
                checked={textBoundaries}
                onChange={handleTextBoundaries}
                name="Toggle text boundaries"
                color="primary"
              />
            }
            label="Toggle text boundaries"
          />


          <FormControl variant="filled" className="topSelect">
            <InputLabel id="preview-language">Preview language</InputLabel>
            <Select
              value={selectedLanguage}
              onChange={(text) => changeGiftcardDisplayLanguage(text)}
              label="Preview language"
              labelId="preview-language"
            >
              {languages.map((lang, index) => {
                return <MenuItem key={index} value={lang}>{isoLangs[lang].name || lang}</MenuItem>
              })}
            </Select>
          </FormControl>
        </Col>
        <Col xs={2} className="nav-right d-none d-lg-flex">
          <Button variant="contained" color="secondary" onClick={resetZoom}>
            Reset zoom
          </Button>

          <Button variant="contained" color="primary" onClick={onRender}>Preview pdf</Button>


        </Col>
      </Row>
    </Navbar>

  );
};


export default TopBar;
