import { shape } from "@mui/system";
import React from "react";
import { Text, Rect, Transformer } from "react-konva";
import gc from 'templatesvgtopdf'

const TextElement = ({ textBoundaries, shapeProps, isSelected, onSelect, onChange, translatedText }) => {
	const shapeRef = React.useRef();
	const trRef = React.useRef();
	const canTransform = isSelected && !shapeProps.locked;

	React.useEffect(() => {
		if (canTransform) {
			//attaching transformer manually
			trRef.current.setNodes([shapeRef.current]);
			trRef.current.getLayer().batchDraw();
		}
	}, [canTransform]);


	// text transformation operations
	const applyTextTransform = (text, transformlist, multiline) => {
		if (!(multiline === undefined || multiline)) {
			text = text.replace(/\n/, ' ')
		}
		try {
			text = gc.applyTextTransform(text, transformlist)
		} catch (err) {
			console.error("errro with text transformation")
		}
		return text
	}

	let { fontStyle, ...customProps } = shapeProps
	// console.log(fontStyle)
	if (fontStyle !== undefined && fontStyle !== null && fontStyle.includes("single")) {
		customProps['fontStyle'] = 'normal'
	} else {
		customProps['fontStyle'] = fontStyle
	}
	// console.log(customProps)
	return (
		<React.Fragment>
			{textBoundaries &&
				<Rect rotation={shapeProps.rotation}
					x={shapeProps.x}
					y={shapeProps.y}
					width={shapeProps.width}
					height={shapeProps.height}
					stroke="#aaa"
					strokeWidth={1}
				/>
			}

			<Text
				/* 	onMouseEnter={onHover}
					onMouseLeave={onHoverEnd} */

				onClick={!shapeProps.locked && onSelect}
				listening={!shapeProps.locked} //can it listen to events?
				ref={shapeRef}
				{...customProps}
				text={applyTextTransform(translatedText,[shapeProps.textTransform ? shapeProps.textTransform : 'none'], shapeProps.multiline)}
			wrap="none"
			draggable={canTransform}
			onDragEnd={e => {
				onChange({
					...shapeProps,
					x: e.target.x(),
					y: e.target.y(),
				});
				onSelect();
			}}

			onTransformEnd={e => {
				//here the transform will change the sce
				const node = shapeRef.current;
				const scaleX = node.scaleX();
				const scaleY = node.scaleY();
				node.scaleX(1);
				node.scaleY(1);
				onChange({
					...shapeProps,
					rotation: node.rotation(),
					x: node.x(),
					y: node.y(),
					width: node.width() * scaleX,
					height: node.height() * scaleY,
				});
			}}
			/>
			{canTransform && <Transformer ref={trRef} />}
		</React.Fragment>
	);
};
export default TextElement;




















