import React from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {Container, Row, Col} from 'reactstrap';
import { SketchPicker } from 'react-color';
import FontSelector from './FontSelector';


const SettingsModal = ({open, handleClose, settings, handleGuideColChange, handleGuideRowChange, handleBackgroundColorChange, handleDefaultFontChange, handleApplyFontToAllElements, backendSettings}) => {

	const validateCols = (e) => {
		if (e.target.value < 0) {
			return false;
		}
		handleGuideColChange(e);
	}
	const validateRows = (e) => {
		if (e.target.value < 0) {
			return false;
		}
		handleGuideRowChange(e);
	}
	const handleColorChange = (color) => {
		handleBackgroundColorChange(color.hex);
	}
	const changeDefaultFont = (font) => {
		handleDefaultFontChange('family', font);
	}

	const toggleDefaultBold = () => {
		handleDefaultFontChange('bold');
	}
	const toggleDefaultItalic = () => {
		handleDefaultFontChange('italic');
	}
	const changeDefaultFontSize = (e) => {
		handleDefaultFontChange('size', parseInt(e.target.value));
	}

	return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">

			<DialogContent>
				<h2>Settings</h2>

				<h5>Guides</h5>
				<p>Set the column and row count for your guides for easier alignment of elements.</p>
				<Row>
					<Col>
						<TextField
							value={settings.guideColumns}
							id="GuideColumns"
							label="Columns"
							type="number"
							variant="outlined"
							onChange={validateCols}
						/>
					</Col>
					<Col>
						<TextField
							value={settings.guideRows}
							type="number"
							id="GuideRows"
							label="Rows"
							variant="outlined"
							onChange={validateRows}
						/>
					</Col>
				</Row>


				<Row>
					<Col>
						<h5 className="mt-4">Paper background color</h5>
						<p>Set the background color for the entire gift card</p>
						<SketchPicker color={settings.backgroundColor} presetColors={[]} disableAlpha={true} onChange={handleColorChange}  />
					</Col>

					<Col>
						<h5 className="mt-4">Default font</h5>
						<p>Set the default font style for the gift card</p>
						<FontSelector
                              backendSettings={backendSettings}
							  fontWeight={settings.defaultFontStyle && settings.defaultFontStyle.includes('bold') ? 'bold' : ''}
                              fontStyle={settings.defaultFontStyle && settings.defaultFontStyle.includes('italic') ? 'italic' : ''}
							  fontType={settings.defaultFontStyle && settings.defaultFontStyle.includes('single') ? 'single' : ''}
                              activeFontFamily={settings.defaultFontFamily}
                              onChangeFont={(nextFont) => changeDefaultFont(nextFont)}
                              toggleBold={toggleDefaultBold}
                              toggleItalic={toggleDefaultItalic}
						/>
						<Button className="mt-3" variant="contained" color="secondary" onClick={() => {if (window.confirm('Are you sure?')) {handleApplyFontToAllElements('family')}}}>Apply this font to all existing elements</Button>
						<h5 className="mt-4">Default font size</h5>
						<input type="number" min="1" onChange={(value) => changeDefaultFontSize(value)} value={settings.defaultFontSize}></input>
						<Button className="mt-3" variant="contained" color="secondary" onClick={() => {if (window.confirm('Are you sure?')) {handleApplyFontToAllElements('size')}}}>Apply this size to all existing elements</Button>
					</Col>
				</Row>


			</DialogContent>
			<DialogActions>
			<Button onClick={handleClose} color="primary">
				Close
			</Button>

			</DialogActions>
		</Dialog>
	);
};


export default SettingsModal;
