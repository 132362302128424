import { NOBG } from '../constants';

// {
//         type:"webshop",
//         languages: ["en", "fi"],
//         endpoints: {
//             fontlist: '/designer/fonts/list',
//             exampletemplatelist: '/designer/publictemplates/list',
//             templatelist: '/designer/templates/list',
//             save: '/designer/templates/save/{id}',
//             load: '/designer/templates/load/{id}',
//             ping: '/designer/ping',
//             render: '/designer/render',
//             dynamictext: '/designer/content/demo',
//             login: '/login',
//             site: '/',
//             defaultpage: '/new-admin/designer',
//             errorexit: '/',
//         },
//         features: {
//             save: true,
//             load: true,
//             templatebrowser: false,
//             publictemplatebrowser: true,
//             rendererbrowser: true,
//             dynamictexts: false,
//         }
//     }

export const fetchBackendSettings = async (settingsUrl) => {

    // await new Promise(resolve => setTimeout(() => { resolve({ data: 'your return data'}) }, 5000))
    let url = settingsUrl ?? ' /designer/settings'

    let settings = NOBG.defaultBackendSettings
    try {
        settings = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => res.json())
    } catch (e) {
        console.log("Error fetching settings from backend")
    }
    return settings
}

export const fetchTemplateBundle = async (url) => {
    return fetch(
        url,
        {
            method: "GET",
            // headers: {
            //   'Content-Type': 'application/json',
            // },
        }
    )
        .then(async response => response.blob())
}


export const fetchImageList = async (customUrl) => {
    let url = customUrl ?? '/api/images/list'
    return fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then(res => res.json())
}

export const fetchFontList = async (customUrl) => {
    let url = customUrl ?? '/designer/fonts/list'
    // /api/fonts/list'
    return fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then(res => res.json())
}

export const fetchTemplateList = async (customUrl) => {
    let url = customUrl ?? '/designer/publictemplates/list'
    //'/api/templates/list'
    return fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then(res => res.json())
}

export const sendTemplateBundle = async (url, blobPayload, extraFields=[]) => {
    let formData = new FormData()

    formData.append('gcdtemplate', blobPayload, 'gcdtemplate')
    const efLen = extraFields.length
    for (let i=0; i<efLen; i++) {
        let {name, data} = extraFields[i]
        console.log(name)
        console.log(data)
        formData.append(name, JSON.stringify(data))
    }

    // form mode
    return fetch(url, {
        method: 'POST', // or 'PUT'
        // mode: 'cors', // no-cors, *cors, same-origin
        // headers: {
        // 'Content-Type': 'application/json',
        // },
        body: formData,
    })

    // return fetch(url, {
    //         method: 'POST', // or 'PUT'
    //         // mode: 'cors', // no-cors, *cors, same-origin
    //         // headers: {
    //         // 'Content-Type': 'application/json',
    //         // },
    //         body: blobPayload,
    //     })
}


export const sendImage = async (url, blobPayload) => {
    return fetch(url, {
        method: 'POST', // or 'PUT'
        // mode: 'cors', // no-cors, *cors, same-origin
        // headers: {
        // 'Content-Type': 'application/json',
        // },
        body: blobPayload,
    })
}

export const sendToRender = async (url, blobPayload) => {
    return sendTemplateBundle(url, blobPayload)
        .then(response => response.blob())
}
export const sendToStorage = async (url, blobPayload, extraFields) => {
    return sendTemplateBundle(url, blobPayload, extraFields).then(response => response.json())

}




export default {
    fetchBackendSettings,
    fetchTemplateBundle,
    sendTemplateBundle,
    sendToRender,
    sendToStorage,
    fetchFontList,
    fetchTemplateList,
    fetchImageList
}


