import React from "react";
import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TemplateSelector from "./TemplateSelector";



const BrowseTemplatesModal = ({ url, open, handleClose, settings, handleChangeTemplate }) => {
	const { templateName } = settings;

	return (
		<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title"
			PaperProps={{
				sx: {
					width: 400,
					// height: 670
					height: 500
				}
			}}>

			<DialogContent>
				<h2>Premade templates</h2>
				<p>Select a template below and click load to start creating your gift card from a template.</p>
				<TemplateSelector
					url={url}
					changeTemplate={handleChangeTemplate}
					activeTemplate={templateName}
					handleClose={handleClose}
				/>

			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary">
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
};


export default BrowseTemplatesModal;
